import { observable, action } from 'mobx';
import { registerUser } from '../../services/http';
import StorageService from '../../services/StorageService';
import CryptoService from '../../services/CryptoService';

class UserStore {
  @observable userData = {
    username: '',
    token: ''
  };

  @action registerUser = async () => {
    const userDataFromStorage = StorageService.getItem('user_data');
    let decryptedData = null;
    if (userDataFromStorage) {
      decryptedData = CryptoService.decryptObj(userDataFromStorage);
    }
    if (userDataFromStorage && decryptedData) {
      this.userData.token = decryptedData.token;
      this.userData.username = decryptedData.username;
    } else {
      this.userData.username = `${new Date().getTime()}_${Math.random()
        .toString(36)
        .substr(2, 9)}`;
      const response = await registerUser(this.userData.username);
      this.userData.token = response.key;
      const dataForLocalStorage = CryptoService.encryptObj(this.userData);
      StorageService.setItem('user_data', dataForLocalStorage);
    }
  };
}

export default new UserStore();
