import React, { useState, useEffect } from 'react';
import Input from '../../atoms/Input';
import AnimatedSelect from '../../organisms/AnimatedSelect';
import { OPTIONS, TIMEOUTS } from '../../../stores/sessionStore/config';

export default ({
  value,
  firstText,
  secondText,
  didMountCallback = () => {},
  didUpdateCallback = () => {},
  onChange = () => {},
  afterChange = () => {},
  afterChangeTimeout = TIMEOUTS.YOUR_BODY,
  options = OPTIONS,
  disabled = false,
  final,
  onFocus = () => {}
}) => {
  const [focused, setSelectStatus] = useState(false);
  const [transform, setTransform] = useState(false);

  useEffect(() => {
    didMountCallback();
  }, []);

  useEffect(() => {
    didUpdateCallback();
    if (disabled) {
      setTransform(false);
      setSelectStatus(false);
    }
  });

  const onFocusHandler = () => {
    setSelectStatus(true);
    onFocus();
    setTimeout(() => setTransform(true), 100);
  };

  const onChangeHandler = text => {
    onChange(text);
    setTransform(false);
    setSelectStatus(false);
    setTimeout(() => {
      afterChange();
    }, afterChangeTimeout);
  };

  return (
    <div className="question" key={value}>
      <div className="question-cont">
        <div className="question-line">
          <span className="back-text">{firstText}</span>
          {final ? (
            <>
              <Input
                placeholder="select below"
                debounceEnabled
                timeout={800}
                defaultValue={value}
                withoutChange
                onFocus={onFocusHandler}
                disabled={disabled}
              />
              <span className="back-text">{secondText}</span>
            </>
          ) : (
            <div style={{ display: 'flex' }} className={secondText.length < 3 ? undefined : 'fix-wrap'}>
              <Input
                placeholder="select below"
                debounceEnabled
                timeout={800}
                defaultValue={value}
                withoutChange
                onFocus={onFocusHandler}
                disabled={disabled}
              />
              <span className="back-text">{secondText}</span>
            </div>
          )}
        </div>
        <div style={{ display: focused ? 'block' : 'none', width: '100%' }}>
          <AnimatedSelect options={options} focus={transform} onChange={onChangeHandler} defaultOption={value} />
        </div>
      </div>
    </div>
  );
};
