import { observable, action } from 'mobx';
import { VIEWPORT_SIZES } from './config';
/* eslint no-nested-ternary: "off" */

class ViewPortStore {
  constructor() {
    this.setViewPort(window.innerWidth);
  }

  @observable viewportWidth = 0;

  @observable isMobileL = false;

  @observable isMobile = false;

  @observable isTablet = false;

  @observable isDesktop = false;

  @observable isWide = false;

  @action setViewPort = size => {
    this.viewportWidth = size;
    const key =
      size <= VIEWPORT_SIZES.MOBILE_L
        ? 'isMobileL'
        : size <= VIEWPORT_SIZES.MOBILE
        ? 'isMobile'
        : size <= VIEWPORT_SIZES.TABLET
        ? 'isTablet'
        : size <= VIEWPORT_SIZES.DESKTOP
        ? 'isDesktop'
        : 'isWide';
    this.toggleKeys(key);
  };

  toggleKeys = key => {
    ['isMobileL', 'isMobile', 'isTablet', 'isDesktop', 'isWide'].forEach(prop => {
      if (key === prop) this[prop] = true;
      else this[prop] = false;
    });
  };
}

export default new ViewPortStore();
