import React from 'react';
import { TIMEOUTS, PARAMS } from '../../../stores/sessionStore/config';
import BorderedSelect from '../../organisms/BorderedSelect';

export default ({
    text,
    afterChange = () => { },
    afterChangeTimeout = TIMEOUTS.YOUR_BODY,
    mainSize,
    alsoBuySize,
    setSize,
    disabled,
    onFocus,
    scale
}) => {
    const setSizeHandler = (name) => (size) => {
        setSize(name, size);
        setTimeout(() => {
            afterChange();
        }, afterChangeTimeout);
    };

    return (
        <div className="question">
            <div className="question-cont">
                <div className="question-line">
                    <span className="back-text">{text}</span>
                </div>
                <div className="card">
                    <BorderedSelect
                        onChange={setSizeHandler(PARAMS.MAIN_SIZE)}
                        label="My main size"
                        options={scale}
                        placeholder={mainSize || 'Select'}
                        disabled={disabled}
                        autoFocus
                        onFocus={onFocus}
                    />
                    <BorderedSelect
                        onChange={setSizeHandler(PARAMS.ALSO_BUY_SIZE)}
                        label="Sometimes I also buy"
                        options={scale}
                        placeholder={alsoBuySize || 'Select'}
                        disabled={disabled}
                        autoFocus
                        onFocus={onFocus}
                    />
                </div>
            </div>
        </div>
    );
};
