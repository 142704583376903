import React, { useState, useRef } from 'react';
import { observer } from 'mobx-react';
import viewportStore from '../../stores/viewportStore';

export default observer(
  ({
    placeholder,
    onChange = () => {},
    timeout = 2000,
    debounceEnabled,
    onFocus = () => {},
    onBlur = () => {},
    defaultValue,
    withoutChange,
    onlyNumber,
    disabled,
    id,
    className = 'custom-input',
    type = 'text'
  }) => {
    const inputRef = useRef(null);
    const [val, setValue] = useState('');
    const [focused, changeFocus] = useState(false);

    const onChangeHandler = event => {
      const { value } = event.target;
      if (onlyNumber) {
        if (value.match(/\D/gi) === null) {
          setValue(value);
          onChange(event);
        }
      } else {
        setValue(value);
        onChange(event);
      }
    };

    const debounce = () => {
      let timer;
      return event => {
        event.persist();
        clearTimeout(timer);
        onChangeHandler(event);
        timer = setTimeout(() => {
          if (typeof onChange === 'function') onChange(event);
        }, timeout);
      };
    };

    const onBlurHandler = event => {
      changeFocus(false);
      onBlur(event);
    };
    const onFocusHandler = event => {
      event.preventDefault();
      changeFocus(true);
      onFocus(event);
      if (viewportStore.isMobile || viewportStore.isMobileL || viewportStore.isTablet) {
        // console.dir(inputRef.current);
        // const rect = inputRef.current.getBoundingClientRect();
        //   inputRef.current.scrollIntoView();
      }
    };
    const debouncedOnChange = debounce();
    const pattern = type === 'number' ? '[0-9]*' : undefined;
    const inputmode = type === 'number' ? 'numeric' : undefined;
    return (
      <span className="inp">
        <input
          type={type}
          pattern={pattern}
          value={debounceEnabled && focused ? val : defaultValue}
          placeholder={placeholder}
          onChange={debounceEnabled ? debouncedOnChange : onChangeHandler}
          onFocus={onFocusHandler}
          onBlur={onBlurHandler}
          readOnly={withoutChange}
          disabled={disabled}
          ref={inputRef}
          id={id}
          inputMode={inputmode}
          className={className}
        />
        <span className="border" />
      </span>
    );
  }
);
