import React from 'react';
import { Spring } from 'react-spring/renderprops';

export default ({ scaleX = 1, skewY = 0, skewX = 0, scaleY = 1, translateX = 0, translateY = 0, className, d }) => {
  return (
    <Spring from={{ transform: 'matrix(1 0 0 1 0 0)' }} to={{ transform: `matrix(${scaleX} ${skewY} ${skewX} ${scaleY} ${translateX} ${translateY})` }}>
      {props => <path transform={props.transform} className={className} d={d} />}
    </Spring>
  );
};
