import React, { useState } from 'react';
import { observer } from 'mobx-react';
import Input from '../../atoms/Input';
import ClothesCheckBox from '../../molecules/ClothesCheckBox';

import { ReactComponent as ClockIcon } from '../../../assets/icons/clock.svg';
import { ReactComponent as LaptopIcon } from '../../../assets/icons/laptop.svg';
import { ReactComponent as GymIcon } from '../../../assets/icons/gym.svg';
import { ReactComponent as WineIcon } from '../../../assets/icons/wine.svg';
import { ReactComponent as HorseIcon } from '../../../assets/icons/horse.svg';
import { ReactComponent as FormalIcon } from '../../../assets/icons/formal.svg';
import { ReactComponent as ClubIcon } from '../../../assets/icons/club.svg';
import { ReactComponent as CarIcon } from '../../../assets/icons/car.svg';
import { ReactComponent as TVIcon } from '../../../assets/icons/tv.svg';
import sessionStore from '../../../stores/sessionStore';
import NextButton from '../../atoms/NextButton';
import { PARAMS, TIMEOUTS } from '../../../stores/sessionStore/config';

const checks = [
  {
    icon: <ClockIcon />,
    text: 'Working at an Office',
    id: 0
  },
  {
    icon: <LaptopIcon />,
    text: 'Working at Home',
    id: 1
  },
  {
    icon: <GymIcon />,
    text: 'Exercising',
    id: 2
  },
  {
    icon: <WineIcon />,
    text: 'Out to Dinner or Drinks',
    id: 3
  },
  {
    icon: <HorseIcon />,
    text: 'Hanging with my Kids ',
    id: 4
  },
  {
    icon: <FormalIcon />,
    text: 'At Formal Events',
    id: 5
  },
  {
    icon: <ClubIcon />,
    text: 'Dancing at the Club',
    id: 6
  },
  {
    icon: <CarIcon />,
    text: 'Driving Carpool',
    id: 7
  },
  {
    icon: <TVIcon />,
    text: 'Chilling at Home',
    id: 8
  }
];

export default observer(({ onNext = () => {}, final = false, value, onFocus = () => {}, withoutNext }) => {
  const [focused, setSelectStatus] = useState(true);
  const [transform, setTransform] = useState(true);
  const { params } = sessionStore;
  const canFind = params[PARAMS.CAN_FIND];

  const onFocusHandler = () => {
    setSelectStatus(true);
    onFocus();
    setTimeout(() => setTransform(true), 100);
  };

  const onCheck = text => () => {
    // onCheck()
    sessionStore.addItem(text);
  };

  const onNextHandler = () => {
    setTransform(false);
    setTimeout(() => setSelectStatus(false), 400);
    setTimeout(() => {
      onNext();
      // if (routes[SECTIONS.EVERYDAY] === 2) {
      //   sessionStore.setQuestion(3, SECTIONS.EVERYDAY);
      // }
    }, TIMEOUTS.EVERYDAY);
  };
  // console.log('AAAAAAAAAAAAA', !final);

  return (
    <div className="question">
      <div className="question-cont">
        <div className="question-line">
          <span className="back-text">In a given week you can find me </span>
          <div style={{ display: 'flex' }}>
            <Input
              placeholder={value || 'select all that apply'}
              withoutChange
              onFocus={onFocusHandler}
              className="icons-input"
            />
            <span>.</span>
          </div>
        </div>
        <div
          className="clothes-container"
          id="scroll-disabled"
          style={{
            display: focused ? 'flex' : 'none',
            transform: transform ? 'scaleX(1) scaleY(1)' : 'scaleX(0) scaleY(0)'
          }}
        >
          <div className="items">
            {checks.map(item => {
              const { text, icon, id } = item;
              return (
                <div className="clothes-card" key={id}>
                  <ClothesCheckBox icon={icon} checked={canFind.indexOf(text) > -1} onClick={onCheck(text)} />
                  <p className="clothes-text">{text}</p>
                </div>
              );
            })}
            <div style={{ width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
              {!final && !withoutNext && <NextButton text="Next" onClick={onNextHandler} disabled={!canFind.length} />}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
});
