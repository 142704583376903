import React from 'react';
import { observer } from 'mobx-react';
import ButtonBack from '../atoms/ButtonBack';
import sessionStore from '../../stores/sessionStore';
import { SECTIONS } from '../../stores/sessionStore/config';

export default observer(({ headerRef }) => {
  const { currentPage, routes, decCurrentStep } = sessionStore;
  const onBack = () => {
    decCurrentStep();
  };
  return (
    <div className="main-header" ref={headerRef}>
      {routes[SECTIONS.MAIN] ? <ButtonBack onClick={onBack} /> : <div className="empty" />}
      <div className="header-content">
        <h4>{currentPage} / 5</h4>
      </div>
    </div>
  );
});
