import React from 'react';
import { observer } from 'mobx-react';
import TransformSpringPath from '../molecules/TransformSpringPath';
import characterStore from '../../stores/characterStore';

export default observer(() => {
  const { scaleX, skewY, skewX, scaleY, translateX, translateY } = characterStore.crotch;
  return (
    <TransformSpringPath
      scaleX={scaleX}
      skewY={skewY}
      skewX={skewX}
      scaleY={scaleY}
      translateX={translateX}
      translateY={translateY}
      className="prefix__cls-2"
      d="M297.035,497.222A18.327,18.327,0,0,1,300.8,498.7a23.477,23.477,0,0,1,3.356,2.1,24.61,24.61,0,0,1,5.463,5.658,28.485,28.485,0,0,1,3.536,6.975,44.614,44.614,0,0,1,1.8,7.527,110.031,110.031,0,0,1,1.154,15.259c.088,5.09-.059,10.171-.283,15.245-.47,10.146-1.368,20.255-2.223,30.358-.4,5.052-.88,10.1-.915,15.167l-.145,15.211-.146-15.211c-.053-5.083.346-10.148.661-15.208.688-10.119,1.416-20.233,1.717-30.357.139-5.061.2-10.124.029-15.173a108.661,108.661,0,0,0-1.385-15.032A43.689,43.689,0,0,0,311.574,514a26.738,26.738,0,0,0-3.409-6.5,22.769,22.769,0,0,0-5.129-5.169,21.546,21.546,0,0,0-3.11-1.887,16.7,16.7,0,0,0-3.313-1.258Z"
    />
  );
});
