import { observable, action } from 'mobx';
import { FOCUS_RECT_CONFIG, SIZE_TYPES, SHORT_SIZE_TYPES } from './config';
import { shoulders, defaultChest, defaultShoulders, defaultWaist, defaultCrotch, defaultNavel } from './paths';
import { cupSizeFactors } from './cupSizes';
import { waistSizeFactors } from './waistSizes';
import { chestSizeFactors } from './chestSizes';
import { hipsSizeFactors } from './hipsSizes';

class CharacterStore {
  @observable currentFocus = FOCUS_RECT_CONFIG.NONE;

  @action setFocusRect = rectType => {
    this.currentFocus = rectType;
  };

  @observable shoulders = defaultShoulders;

  @observable chest = defaultChest;

  @observable waist = defaultWaist;

  @observable crotch = defaultCrotch;

  @observable navel = defaultNavel;

  @action setShoulders = size => {
    this.shoulders = shoulders[size];
  };

  cupSize = null;

  chestSize = null;

  waistSize = null;

  legsSize = null;

  @action setChestSize = chestSize => {
    this.waistSize = null;
    this.waist = defaultWaist;

    if (this.chestSize) {
      if (!this.cupSize) {
        this.chest.left.translateX = chestSizeFactors[chestSize].translateX.left;
        this.chest.right.translateX = chestSizeFactors[chestSize].translateX.right;
      } else {
        this.chestSize = null;
        this.setCupSizeChest(this.cupSize);
        this.chest.left.translateX += chestSizeFactors[chestSize].translateX.left;
        this.chest.right.translateX += chestSizeFactors[chestSize].translateX.right;
      }
    } else if (this.cupSize) {
      this.chest.left.translateX += chestSizeFactors[chestSize].translateX.left;
      this.chest.right.translateX += chestSizeFactors[chestSize].translateX.right;
    } else {
      this.chest.left.translateX = chestSizeFactors[chestSize].translateX.left;
      this.chest.right.translateX = chestSizeFactors[chestSize].translateX.right;
    }

    this.waist.left.skewY = chestSizeFactors[chestSize].waistSkewY.left;
    this.waist.left.skewX = chestSizeFactors[chestSize].waistSkewX.left;
    this.waist.left.translateX = chestSizeFactors[chestSize].waistTranslateX.left;
    this.waist.left.translateY = chestSizeFactors[chestSize].waistTranslateY.left;

    this.waist.right.skewY = chestSizeFactors[chestSize].waistSkewY.right;
    this.waist.right.skewX = chestSizeFactors[chestSize].waistSkewX.right;
    this.waist.right.translateX = chestSizeFactors[chestSize].waistTranslateX.right;
    this.waist.right.translateY = chestSizeFactors[chestSize].waistTranslateY.right;
    this.chestSize = chestSize;
  };

  @action setCupSizeChest = cupSize => {
    // console.log(cupSize);
    if (this.waistSize) {
      this.waistSize = null;
      this.waist = defaultWaist;
    }
    if (this.cupSize) {
      if (!this.bandSize) {
        this.chest.left.translateX = cupSizeFactors[cupSize].translateX.left;
        this.chest.left.translateY = cupSizeFactors[cupSize].translateY.left;
        this.chest.right.translateX = cupSizeFactors[cupSize].translateX.right;
        this.chest.right.translateY = cupSizeFactors[cupSize].translateY.right;
      } else {
        this.chest = defaultChest;
        this.cupSize = null;
        this.setBandSizeChest(this.bandSize);
        this.chest.left.translateX += cupSizeFactors[cupSize].translateX.left;
        this.chest.left.translateY += cupSizeFactors[cupSize].translateY.left;
        this.chest.right.translateX += cupSizeFactors[cupSize].translateX.right;
        this.chest.right.translateY += cupSizeFactors[cupSize].translateY.right;
      }
    } else {
      this.chest.left.translateX += cupSizeFactors[cupSize].translateX.left;
      this.chest.left.translateY += cupSizeFactors[cupSize].translateY.left;
      this.chest.right.translateX += cupSizeFactors[cupSize].translateX.right;
      this.chest.right.translateY += cupSizeFactors[cupSize].translateY.right;
    }

    this.chest.left.scaleX = cupSizeFactors[cupSize].scaleX.left;
    this.chest.left.skewY = cupSizeFactors[cupSize].skewY.left;
    this.chest.left.skewX = cupSizeFactors[cupSize].skewX.left;
    this.chest.left.scaleY = cupSizeFactors[cupSize].scaleY.left;

    this.chest.right.scaleX = cupSizeFactors[cupSize].scaleX.right;
    this.chest.right.skewY = cupSizeFactors[cupSize].skewY.right;
    this.chest.right.skewX = cupSizeFactors[cupSize].skewX.right;
    this.chest.right.scaleY = cupSizeFactors[cupSize].scaleY.right;

    this.cupSize = cupSize;
  };

  @action setWaistSize = size => {
    this.waistSize = size;
    const currentSize = waistSizeFactors[size];
    this.waist.left.scaleX = currentSize.scaleX.left;
    this.waist.left.skewY = currentSize.skewY.left;
    this.waist.left.skewX = currentSize.skewX.left;
    this.waist.left.scaleY = currentSize.scaleY.left;

    this.waist.right.scaleX = currentSize.scaleX.right;
    this.waist.right.skewY = currentSize.skewY.right;
    this.waist.right.skewX = currentSize.skewX.right;
    this.waist.right.scaleY = currentSize.scaleY.right;

    this.waist.left.translateX = currentSize.translateX.left;
    this.waist.left.translateY = currentSize.translateY.left;
    this.waist.right.translateX = currentSize.translateX.right;
    this.waist.right.translateY = currentSize.translateY.right;
  };

  @action setHipsSize = size => {
    const currentSize = hipsSizeFactors[size];
    let dopValueXLeft = 0;
    let dopValueXRight = 0;
    const lastLeft = this.waist.left.scaleX;
    const lastRight = this.waist.right.scaleX;
    const lastTranslateLeft = this.waist.left.translateX;
    const lastTranslateRight = this.waist.right.translateX;
    this.waist.left.scaleX = (currentSize.scaleX.left + lastLeft) / 2;
    this.waist.right.scaleX = (currentSize.scaleX.right + lastRight) / 2;

    if (this.waistSize === SIZE_TYPES.SMALLEST) {
      dopValueXLeft = -20;
      dopValueXRight = 16;
    }
    this.waist.left.translateX = (currentSize.translateX.left + lastTranslateLeft) / 2 + dopValueXLeft;
    this.waist.right.translateX = (currentSize.translateX.right + lastTranslateRight) / 2 + dopValueXRight;
    this.waist.left.skewY = 0;
    this.waist.left.skewX = 0;
    this.waist.left.scaleY = 1;
    this.waist.left.translateY = 0;
    this.waist.right.skewY = 0;
    this.waist.right.skewX = 0;
    this.waist.right.scaleY = 1;
    this.waist.right.translateY = 0;
  };

  @action setLegs = size => {
    if (size === SHORT_SIZE_TYPES.SMALL || size === SHORT_SIZE_TYPES.MEDIUM) {
      this.chest = defaultChest;
      // console.log(this.chestSize, this.cupSize);
      this.setChestSize(this.chestSize);
      this.setCupSizeChest(this.cupSize);
    }

    if (size === SHORT_SIZE_TYPES.BIG) {
      this.shoulders.left.scaleY = 0.819234;
      this.shoulders.right.scaleY = 0.819234;
      this.shoulders.left.translateY = 43.5393;
      this.shoulders.right.translateY = 43.5393;

      if (this.legsSize !== SHORT_SIZE_TYPES.BIG && size === SHORT_SIZE_TYPES.BIG) {
        const lastScaleYLeft = this.chest.left.scaleY;
        const lastScaleYRight = this.chest.right.scaleY;
        this.chest.left.scaleY = (0.819234 + lastScaleYLeft) / 2;
        this.chest.right.scaleY = (0.819234 + lastScaleYRight) / 2;
        if (this.chest.left.translateY < 0 && this.chest.right.translateY < 0) {
          this.chest.left.translateY += 35.5393;
          this.chest.right.translateY += 38.5393;
        } else {
          this.chest.left.translateY -= 5.5393;
          this.chest.right.translateY -= 5.5393;
        }
      }
      this.waist.left.skewY = 0;
      this.waist.right.skewY = 0;

      this.waist.left.translateY = -71.0398;
      this.waist.right.translateY = -71.0398;
      this.waist.left.scaleY = 1.11395;
      this.waist.right.scaleY = 1.11395;

      this.crotch.scaleY = 1.41328;
      this.crotch.translateX = 2.22514;
      this.crotch.translateY = -247.096;

      this.navel.scaleY = 1.11395;
      this.navel.translateY = -71.0398;
    }
    if (size === SHORT_SIZE_TYPES.SMALL) {
      this.waist.left.skewY = 0;
      this.waist.right.skewY = 0;

      this.waist.left.scaleY = 0.970428;
      this.waist.right.scaleY = 0.970428;
      this.waist.left.translateY = 18.4856;
      this.waist.right.translateY = 18.4856;
      this.crotch.scaleY = 0.864083;
      this.crotch.translateX = 0;
      this.crotch.translateY = 87.1934;

      this.navel.scaleY = 0.970428;
      this.navel.translateY = 46.2999;
    }
    if (size === SHORT_SIZE_TYPES.MEDIUM) {
      this.navel = defaultNavel;
      this.crotch = defaultCrotch;
    }
    this.legsSize = size;
  };
}

export default new CharacterStore();
