import { CUP_SIZES } from './config';
import { sizeFactors, DIRECTION_TYPES, factorsMerge } from '../../utils/sizeFactors';

const allSizeFactors = (() => {
  const arr = CUP_SIZES;
  // scaleX
  const maxScaleX = {
    left: 1.12821,
    right: 1.15215
  };
  const minScaleX = {
    left: 0.810476,
    right: 0.797963
  };
  const scaleX = sizeFactors(arr, maxScaleX, minScaleX, 'scaleX', DIRECTION_TYPES.LPRP_to_LPRP);
  // skewY
  const maxSkewY = {
    left: 0.118272,
    right: -0.0398374
  };
  const minSkewY = {
    left: -0.0436201,
    right: 0.0347282
  };
  const skewY = sizeFactors(arr, maxSkewY, minSkewY, 'skewY', DIRECTION_TYPES.LMRP_to_LPRM);
  // skewX
  const maxSkewX = {
    left: -0.118272,
    right: 0.0398374
  };
  const minSkewX = {
    left: 0.0436201,
    right: -0.0347282
  };
  const skewX = sizeFactors(arr, maxSkewX, minSkewX, 'skewX', DIRECTION_TYPES.LPRM_to_LMRP);
  // scaleY
  const maxScaleY = {
    left: 1.12821,
    right: 1.15215
  };
  const minScaleY = {
    left: 0.810476,
    right: 0.797963
  };
  const scaleY = sizeFactors(arr, maxScaleY, minScaleY, 'scaleY', DIRECTION_TYPES.LPRP_to_LPRP);
  // translateX
  const maxTranslateX = {
    left: -1.18912,
    right: -60.5643
  };
  const minTranslateX = {
    left: 45.4268,
    right: 74.2075
  };
  const translateX = sizeFactors(arr, maxTranslateX, minTranslateX, 'translateX', DIRECTION_TYPES.LPRP_to_LMRM);
  // translateY
  const maxTranslateY = {
    left: -70.6284,
    right: -31
  };
  const minTranslateY = {
    left: 78.1185,
    right: 57.2971
  };
  const translateY = sizeFactors(arr, maxTranslateY, minTranslateY, 'translateY', DIRECTION_TYPES.LPRP_to_LMRM);
  const result = factorsMerge(scaleX, skewY, skewX, scaleY, translateX, translateY);

  return result;
})();

export const cupSizeFactors = allSizeFactors;
