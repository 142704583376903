import { SIZE_TYPES, SHORT_SIZE_TYPES } from '../characterStore/config';

export const STEPS = [
    {
        title: 'What’s most important to you when shopping for jeans online?'
    },
    {
        title: 'We get it! Finding jeans that actually fit shouldn’t be so hard.'
    },
    {
        title: 'First, tell us a bit about your beautiful body'
    },
    {
        title: 'Now, we want to learn your favorite go-to brands: '
    },
    {
        title: 'What about your perfect fit?'
    },
    {
        title: 'Let’s make sure our recommendations cater to your everyday — tell us a bit more about yourself! '
    }
];

export const SECTIONS = {
    MAIN: 'main',
    YOUR_BODY: 'body',
    PERFECT_FIT: 'fit',
    EVERYDAY: 'everyday'
};

export const PARAMS = {

    TOPS: 'tops',
    BOTTOMS: 'bottoms',
    FAVORITE_BODY_PART: 'favoriteBodyPart',
    FRUSTRATING_FOR: 'frustratingFor',
    AGE: 'age',
    SPEND_DAY: 'spendDay',
    CAN_FIND: 'canFind',
    FEET: 'feet',
    INCHES: 'inches',
    LBS: 'lbs',
    SHOULDERS: 'shoulders',
    BAND: 'band',
    CUP: 'cup',
    CHEST: 'chest',
    WAIST: 'waist',
    LEGS: 'legs',
    BODY_TYPE: 'bodyType',
    CLOTHING_ONLINE: 'clothingOnline',
    MAIN_SIZE: 'mainSize',
    SCALE: 'scale',
    ALSO_BUY_SIZE: 'alsoBuySize'
};

export const BODY_QUESTIONS = [
    {
        INCHES: PARAMS.INCHES,
        FEET: PARAMS.FEET,
        LBS: PARAMS.LBS
    },
    {
        SHOULDERS: PARAMS.SHOULDERS
    },
    {
        BAND: PARAMS.BAND,
        CUP: PARAMS.CUP
    },
    {
        CHEST: PARAMS.CHEST
    },
    {
        WAIST: PARAMS.WAIST
    },
    {
        LEGS: PARAMS.LEGS
    },
    {
        BODY_TYPE: PARAMS.BODY_TYPE
    }
];

export const EVERYDAY_QUESTIONS = [{ AGE: PARAMS.AGE }, { SPEND_DAY: PARAMS.SPEND_DAY }, { CAN_FIND: PARAMS.CAN_FIND }];

export const PERFECT_FIT_QUESTIONS = [
    { SCALE: PARAMS.SCALE },
    { MAIN_SIZE: PARAMS.MAIN_SIZE, ALSO_BUY_SIZE: PARAMS.ALSO_BUY_SIZE },
    { TOPS: PARAMS.TOPS },
    { BOTTOMS: PARAMS.BOTTOMS },
    { FAVORITE_BODY_PART: PARAMS.FAVORITE_BODY_PART },
    { FRUSTRATING_FOR: PARAMS.FRUSTRATING_FOR }
];
export const VALUES = {
    'much narrower': SIZE_TYPES.SMALLEST,
    'slightly narrower': SIZE_TYPES.SMALL,
    'roughly the same': SIZE_TYPES.MEDIUM,
    'slightly wider': SIZE_TYPES.BIG,
    'much wider': SIZE_TYPES.BIGGEST
};

export const OPTIONS = Object.keys(VALUES);

export const SHORT_VALUES = {
    shorter: SHORT_SIZE_TYPES.SMALL,
    'roughly the same': SHORT_SIZE_TYPES.MEDIUM,
    longer: SHORT_SIZE_TYPES.BIG
};

export const TIMEOUTS = {
    YOUR_BODY: 500,
    PERFECT_FIT: 1000,
    EVERYDAY: 1000
};

export const BRANDS_SIZES = [
    '23',
    '24',
    '25',
    '26',
    '27',
    '28',
    '29',
    '30',
    '31',
    '32',
    '33',
    '34',
    '35',
    '36',
    '37',
    '38',
    '39',
    '40',
    '41',
    '42',
    '44',
    '46',
    '48',
    '49',
    '50',
    '50+'
];

export const BRANDS_SIZES_ALT = [
    "0", "2", "4", "6", "8", "10", "12", "14", "16", "18", "20", "22", "24", "26", "28", "30", "32", "34", "36", "38", "40", "40+"
];

export const SHORT_OPTIONS = Object.keys(SHORT_VALUES);

export const BODY_TYPE_OPTIONS = ['soft', 'lean', 'muscular'];

export const TOPS_BOTTOMS_OPTIONS = [
    'very loose',
    'slightly loose',
    'neither loose nor fitted',
    'slightly fitted',
    'very fitted'
];

export const CONVERT_MATRIX = {
    'much narrower': 1,
    'slightly narrower': 2,
    'roughly the same': 3,
    'slightly wider': 4,
    'much wider': 5
};

export const BODY_PART_OPTIONS = ['neck', 'arms', 'shoulders', 'chest', 'waist', 'legs', 'butt'];
