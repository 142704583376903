import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import smoothscroll from 'smoothscroll-polyfill';
import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import * as serviceWorker from './serviceWorker';
import './assets/fonts/FuturaPTBook.otf';
import './assets/fonts/Serif6Beta-Regular.otf';
import './styles/_index.scss';

if (!document.getElementById('app')) {
  document.body.appendChild(document.createElement('div')).id = 'app';
}

smoothscroll.polyfill();

ReactDOM.render(<App />, document.getElementById('app'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
