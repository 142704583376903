/* eslint-disable */
const documentOffsetTop = element => {
  let res = 0;
  const rec = el => {
    res += el.offsetTop;
    if (el.offsetParent) rec(el.offsetParent);
  };
  if (element) rec(element);
  return res;
};

export const documentOffsetTopWithHeader = element => {
  const header = document.getElementsByClassName('main-header-bar')[0];
  if (header && header.offsetHeight) {
    return (
      documentOffsetTop(element) -
      header.offsetHeight -
      (window.innerWidth > 1200 ? 12 : window.innerWidth > 2800 ? 20 : 0)
    );
  }
  return documentOffsetTop(element) - (window.innerWidth > 1200 ? 80 : window.innerWidth > 2800 ? 88 : 68);
};

export default documentOffsetTop;
