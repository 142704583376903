import React from 'react';

const ScaleButton = ({ text, onClick, disabled, selected }) => {
    return (
        <div className={`scale-btn ${selected && "selected-scale-btn"}`} onClick={onClick} onKeyDown={onClick} role="button" tabIndex={0} disabled={disabled}>
            <span className="scale-btn-text">{text}</span>
        </div>
    );
};

export default ({ text, firstScaleText, secondScaleText, onFirstScaleClick, onSecondScaleClick, disabled, selected }) => {
    return (
        <div className="question">
            <div className="question-cont">
                <div className="question-line">
                    <span className="back-text">{text}</span>
                </div>
                <div className="card">
                    <ScaleButton text={firstScaleText} onClick={onFirstScaleClick} disabled={disabled} selected={selected ? firstScaleText === selected : true} />
                    <ScaleButton text={secondScaleText} onClick={onSecondScaleClick} disabled={disabled} selected={selected ? secondScaleText === selected : true} />
                </div>
            </div>
        </div>
    );
};
