import React from 'react';
import { observer } from 'mobx-react';
import TransformSpringPath from '../molecules/TransformSpringPath';
import characterStore from '../../stores/characterStore';

export default observer(() => {
  const { left, right } = characterStore.waist;
  return (
    <>
      <TransformSpringPath
        scaleX={left.scaleX}
        skewY={left.skewY}
        skewX={left.skewX}
        scaleY={left.scaleY}
        translateX={left.translateX}
        translateY={left.translateY}
        className="prefix__cls-2"
        d="M269.347,371.477c2.591,10.139,4.864,20.366,6.525,30.721.4,2.6.775,5.182.941,7.842a28.723,28.723,0,0,1-.844,7.931,60.582,60.582,0,0,1-6.114,14.569c-2.537,4.6-5.305,9.048-7.979,13.537s-5.279,9.008-7.619,13.661a105.64,105.64,0,0,0-10.084,29.39,96.48,96.48,0,0,0-1.472,15.49c-.055,2.592.067,5.2.139,7.809.045,2.608.216,5.209.388,7.809.316,5.2.939,10.374,1.682,15.522.864,5.13,1.848,10.237,3.143,15.283,1.248,5.057,2.688,10.07,4.107,15.106s2.826,10.082,4.128,15.159c2.551,10.171,4.751,20.434,6.466,30.771l-1.326.24c-2.039-10.247-4.3-20.44-7.049-30.511-2.657-10.093-5.821-20.066-8.26-30.3-1.258-5.1-2.183-10.29-3-15.484-.689-5.214-1.253-10.445-1.508-15.69-.142-2.621-.293-5.242-.313-7.865-.047-2.623-.139-5.241-.081-7.88a97.943,97.943,0,0,1,1.551-15.715,106.983,106.983,0,0,1,10.316-29.73c2.377-4.686,5.01-9.23,7.713-13.715s5.476-8.918,8.006-13.471A59.468,59.468,0,0,0,274.86,417.7a28.117,28.117,0,0,0,.85-7.619c-.108-2.565-.451-5.167-.779-7.749-1.406-10.346-3.432-20.619-5.778-30.809Z"
      />
      <TransformSpringPath
        scaleX={right.scaleX}
        skewY={right.skewY}
        skewX={right.skewX}
        scaleY={right.scaleY}
        translateX={right.translateX}
        translateY={right.translateY}
        className="prefix__cls-2"
        d="M358.139,370.987c-2.347,10.19-4.372,20.464-5.778,30.809-.329,2.582-.671,5.185-.779,7.75a28.153,28.153,0,0,0,.849,7.618,59.536,59.536,0,0,0,6.054,14.26c2.53,4.552,5.309,8.981,8.006,13.47s5.335,9.029,7.713,13.715a107.016,107.016,0,0,1,10.316,29.73,97.954,97.954,0,0,1,1.551,15.715c.058,2.639-.035,5.257-.082,7.88-.019,2.623-.171,5.244-.312,7.866-.255,5.244-.82,10.475-1.509,15.689-.813,5.195-1.738,10.382-3,15.484-2.439,10.229-5.6,20.2-8.259,30.3-2.749,10.071-5.01,20.265-7.049,30.511l-1.326-.239c1.714-10.338,3.915-20.6,6.465-30.771,1.3-5.078,2.715-10.126,4.128-15.16s2.86-10.049,4.107-15.106c1.3-5.046,2.279-10.153,3.144-15.283.743-5.148,1.366-10.319,1.682-15.522.172-2.6.342-5.2.388-7.809.072-2.606.193-5.217.139-7.809a96.458,96.458,0,0,0-1.473-15.489,105.617,105.617,0,0,0-10.084-29.39c-2.339-4.654-4.939-9.178-7.619-13.662s-5.442-8.934-7.978-13.537a60.582,60.582,0,0,1-6.114-14.569,28.681,28.681,0,0,1-.844-7.931c.165-2.66.541-5.247.941-7.842,1.661-10.354,3.933-20.582,6.524-30.721Z"
      />
    </>
  );
});
