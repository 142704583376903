import React, { Component } from 'react';
import { observer } from 'mobx-react';
import characterStore from '../../stores/characterStore';
import Head from './Head';
import Neck from './Neck';
import Shoulders from './Shoulders';
import Chest from './Chest';
import Waist from './Waist';
import FocusRect from './FocusRect';
import Crotch from './Crotch';
import Navel from './Navel';

@observer
class SvgComponent extends Component {
  render() {
    const { currentFocus } = characterStore;
    return (
      <svg
        id="prefix__\u0421\u043B\u043E\u0439_1"
        data-name="\u0421\u043B\u043E\u0439 1"
        viewBox="0 0 633.747 754.319"
        width="100%"
      >
        <defs>
          <style>
            {
              '.prefix__cls-1{fill:#e8e1e7}.prefix__cls-2{fill:#7e587f}.prefix__cls-3{fill:#b5a0b4}.prefix__cls-4{fill:#ffffff}.prefix__cls-5{fill:rgba(181, 160, 180, 0.2)}'
            }
          </style>
        </defs>
        <FocusRect currentFocus={currentFocus} />
        <Head />
        <Neck />
        <Shoulders />
        <Chest />
        <Navel />
        <Waist />
        <Crotch />
      </svg>
    );
  }
}

export default SvgComponent;
