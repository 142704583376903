export default (() => {
  let timer;
  return (callback, timeout) => {
    return () => {
      clearTimeout(timer);
      timer = setTimeout(() => {
        callback();
      }, timeout);
    };
  };
})();
