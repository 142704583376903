import CryptoJS from 'crypto-js';

const KEY = 'LOCAL_KEY';

class CryptoService {
  encryptObj = item => {
    try {
      const stringifiedItem = JSON.stringify(item);
      return CryptoJS.AES.encrypt(stringifiedItem, KEY).toString();
    } catch (error) {
      return null;
    }
  };

  decryptObj = text => {
    try {
      const bytes = CryptoJS.AES.decrypt(text, KEY);
      const originalText = bytes.toString(CryptoJS.enc.Utf8);
      if (originalText) return JSON.parse(originalText);
      return null;
    } catch (error) {
      return null;
    }
  };
}

export default new CryptoService();
