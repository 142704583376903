/* eslint no-nested-ternary: "off" */

export const DIRECTION_TYPES = {
  LPRP_to_LPRP: 'MIN ( left+ right+) to MAX ( left+ right+ )',
  LMRP_to_LPRM: 'MIN ( left- right+) to MAX ( left+ right- )',
  LPRM_to_LMRP: 'MIN ( left+ right-) to MAX ( left- right+ )',
  LPRP_to_LMRM: 'MIN ( left+ right+) to MAX ( left- right- )',
  LPRP_to_LPRM: 'MIN ( left+ right+) to MAX ( left+ right- )'
};

export const sizeFactors = (arr, maxValues, minValues, propName, direction, revert) => {
  const result = {};
  const maxVal = JSON.parse(JSON.stringify(maxValues));
  const minVal = JSON.parse(JSON.stringify(minValues));
  const { length } = arr;
  let i = 0;
  let leftStep;
  let rightStep;
  const maxLeftAbsValue = Math.max(Math.abs(maxVal.left), Math.abs(minVal.left));
  const minLeftAbsValue = Math.min(Math.abs(maxVal.left), Math.abs(minVal.left));
  const maxRightAbsValue = Math.max(Math.abs(maxVal.right), Math.abs(minVal.right));
  const minRightAbsValue = Math.min(Math.abs(maxVal.right), Math.abs(minVal.right));
  if (direction === DIRECTION_TYPES.LPRP_to_LPRP) {
    leftStep = (maxLeftAbsValue - minLeftAbsValue) / (length - 1);
    rightStep = (maxRightAbsValue - minRightAbsValue) / (length - 1);
  }
  if (direction === DIRECTION_TYPES.LPRP_to_LPRM) {
    leftStep = (maxLeftAbsValue - minLeftAbsValue) / (length - 1);
    rightStep = (maxRightAbsValue + minRightAbsValue) / (length - 1);
  }
  if (
    direction === DIRECTION_TYPES.LMRP_to_LPRM ||
    direction === DIRECTION_TYPES.LPRM_to_LMRP ||
    direction === DIRECTION_TYPES.LPRP_to_LMRM
  ) {
    if (revert) {
      leftStep = (maxLeftAbsValue - minLeftAbsValue) / (length - 1);
      rightStep = (maxRightAbsValue - minRightAbsValue) / (length - 1);
    } else {
      leftStep = (maxLeftAbsValue + minLeftAbsValue) / (length - 1);
      rightStep = (maxRightAbsValue + minRightAbsValue) / (length - 1);
    }
  }

  while (i < length) {
    result[arr[i]] = {
      [propName]: {
        left: !i
          ? minVal.left
          : direction === DIRECTION_TYPES.LPRP_to_LPRP
          ? (minVal.left += leftStep)
          : direction === DIRECTION_TYPES.LPRP_to_LMRM
          ? (minVal.left -= leftStep)
          : direction === DIRECTION_TYPES.LMRP_to_LPRM
          ? (minVal.left += leftStep)
          : (minVal.left -= leftStep),
        right: !i
          ? minVal.right
          : direction === DIRECTION_TYPES.LPRP_to_LPRP
          ? (minVal.right += rightStep)
          : direction === DIRECTION_TYPES.LPRP_to_LMRM
          ? (minVal.right -= rightStep)
          : direction === DIRECTION_TYPES.LMRP_to_LPRM
          ? (minVal.right -= rightStep)
          : (minVal.right += rightStep)
      }
    };
    i += 1;
  }
  return result;
};

export const factorsMerge = (...args) => {
  const result = { ...args[0] };
  const { length } = args;
  const propsGiving = obj =>
    Object.keys(obj).forEach(prop => {
      result[prop] = { ...result[prop], ...obj[prop] };
    });
  let i = 0;
  while (i < length) {
    propsGiving(args[i]);
    i += 1;
  }
  return result;
};
