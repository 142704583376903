import React from 'react';
import { Spring } from 'react-spring/renderprops';

export default ({ x, y, width, height, fill }) => {
  return (
    <Spring from={{ x: 316.5, y: 377, width: 0, height: 0 }} to={{ x, y, width, height }}>
      {props => <rect x={props.x} y={props.y} width={props.width} height={props.height} fill={fill} />}
    </Spring>
  );
};
