import React, { useState, useEffect } from 'react';
import { observer } from 'mobx-react';
import { useTrail, animated } from 'react-spring';
import Option from '../molecules/Option';

const config = { mass: 5, tension: 4000, friction: 200 };

export default observer(({ options, focus, onChange, additionalClass, defaultOption }) => {
  const [currentOption, setCurrent] = useState(-1);

  useEffect(() => {
    const defOpt = options.indexOf(defaultOption);
    if (defOpt > -1) {
      setCurrent(defOpt);
    }
  }, []);

  const optionHeight = 60;

  const trail = useTrail(options.length, {
    config,
    opacity: focus ? 1 : 0,
    x: focus ? 0 : 20,
    height: focus ? optionHeight : 0,
    from: { opacity: 0, x: 20, height: 0 }
  });

  const createClickHandler = index => () => {
    setCurrent(index);
    if (typeof onChange === 'function') onChange(options[index]);
  };

  return (
    <div className={`select ${additionalClass || ''}`}>
      {trail.map(({ x, height, ...rest }, index) => (
        <animated.div
          key={options[index]}
          className="trails"
          style={{ ...rest, transform: x.interpolate(xCoord => `translate3d(0,${xCoord}px,0)`) }}
        >
          <Option
            value={options[index]}
            height={height}
            checked={index === currentOption}
            onClick={createClickHandler(index)}
          />
        </animated.div>
      ))}
    </div>
  );
});
