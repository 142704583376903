import React, { Component } from 'react';
import { observer } from 'mobx-react';
import { ReactComponent as DressIcon } from '../../../assets/icons/dress.svg';
import { ReactComponent as TopsIcon } from '../../../assets/icons/tops.svg';
import { ReactComponent as PantsIcon } from '../../../assets/icons/pants.svg';
import { ReactComponent as JeansIcon } from '../../../assets/icons/jeans.svg';
import sessionStore from '../../../stores/sessionStore';
import {
    SECTIONS,
    PERFECT_FIT_QUESTIONS,
    BODY_PART_OPTIONS,
    PARAMS,
    TOPS_BOTTOMS_OPTIONS,
    BRANDS_SIZES,
    BRANDS_SIZES_ALT
} from '../../../stores/sessionStore/config';
import QuestionAnimatedSelect from '../../templates/questionTemplates/QuestionAnimatedSelect';
import QuestioIcons from '../../templates/questionTemplates/QuestioIcons';
import QuestionScroller from '../../organisms/Scroller';
import NextButton from '../../atoms/NextButton';
import { sendAnswer, brandsSizesAlt } from '../../../services/http';
import viewportStore from '../../../stores/viewportStore';
import InlineButton from '../../atoms/InlineButton';
import documentOffsetTop, { documentOffsetTopWithHeader } from '../../../utils/documentOffsetTop';
import QuestionBorderedSelect from '../../templates/questionTemplates/QuestionBorderedSelect';
import QuestionScales from '../../templates/questionTemplates/QuestionScales';

const checks = [
    {
        icon: <DressIcon />,
        text: 'Dresses',
        value: 'dresses'
    },
    {
        icon: <TopsIcon />,
        text: 'Tops',
        value: 'tops'
    },
    {
        icon: <PantsIcon />,
        text: 'Pants',
        value: 'pants'
    },
    {
        icon: <JeansIcon />,
        text: 'Jeans',
        value: 'jeans'
    }
];
@observer
class PerfectFitSection extends Component {
    constructor(props) {
        super(props);
        this.state = {
            initial: true,
            final: false,
            disabled: false,
            isPrevious: false,
            finalHeight: ''
        };
        this.scroller = null;
    }

    componentDidMount() {
        const currentQuestion = sessionStore.routes[SECTIONS.PERFECT_FIT];
        setTimeout(() => {
            this.setState({ initial: false });
        }, 100);
        if (currentQuestion > 5) {
            this.setState({ final: true });
        } else if (this.canScrollNext(currentQuestion)) {
            this.setState({ isPrevious: true });
        }
        document.addEventListener('keydown', this.onEnterHandler);
    }

    componentDidUpdate() {
        const { final, finalHeight } = this.state;
        const mobile = viewportStore.isMobile || viewportStore.isMobileL;

        if (final && !finalHeight) {
            const title = document.getElementsByClassName('title-cont')[0];
            // console.log(title);
            if (mobile) {
                window.scrollTo({ top: documentOffsetTop(title), behavior: 'smooth' });
            } else {
                window.scrollTo({ top: documentOffsetTopWithHeader(title), behavior: 'smooth' });
            }
            setTimeout(() => {
                this.setFinalContentHeight();
            }, 100);
        }
    }

    componentWillUnmount() {
        document.removeEventListener('keydown', this.onEnterHandler);
    }

    setFinalContentHeight = () => {
        const finalContent = document.getElementsByClassName('scroller-content')[0];
        this.setState({ finalHeight: finalContent.getBoundingClientRect().height + 15 });
    };

    onEnterHandler = event => {
        const { final } = this.state;
        const currentRoute = sessionStore.routes[SECTIONS.PERFECT_FIT];
        if (event.key === 'Enter' && !final && this.canScrollNext()) {
            sessionStore.setQuestion(currentRoute + 1, SECTIONS.PERFECT_FIT);
        }
    };

    canScrollNext = () => {

        const currentRoute = sessionStore.routes[SECTIONS.PERFECT_FIT];
        let permission = false;
        if (!PERFECT_FIT_QUESTIONS[currentRoute]) return permission;
        console.log('currentRoute', currentRoute);
        console.log(sessionStore.params, PARAMS.SCALE);
        console.log('Object.values(PERFECT_FIT_QUESTIONS[currentRoute])', Object.values(PERFECT_FIT_QUESTIONS[currentRoute]));
        permission = Object.values(PERFECT_FIT_QUESTIONS[currentRoute]).every(key => {
            console.log('sessionStore.params[key]', sessionStore.params[key], key);
            return sessionStore.params[key];
        });
        console.log('canScrollNext', permission);
        return permission;
    };

    onClick = num => {
        sessionStore.setQuestion(num, SECTIONS.PERFECT_FIT);
    };

    onNext = () => {
        console.log('onNext');
        const currentQuestion = sessionStore.routes[SECTIONS.PERFECT_FIT];
        const { final } = this.state;
        if (currentQuestion === 5 && this.canScrollNext(5)) {
            sessionStore.setQuestion(currentQuestion + 1, SECTIONS.PERFECT_FIT);
            this.setState({ final: true });
        }
        if (this.canScrollNext() && !final) {
            sessionStore.setQuestion(currentQuestion + 1, SECTIONS.PERFECT_FIT);
        }
        if (this.canScrollNext(currentQuestion)) {
            this.setState({ isPrevious: true });
        } else {
            this.setState({ isPrevious: false });
        }
    };

    onPrev = () => {
        const { final } = this.state;
        if (sessionStore.routes[SECTIONS.PERFECT_FIT] > 0 && !final) {
            this.setState({ isPrevious: true });
            sessionStore.setQuestion(sessionStore.routes[SECTIONS.PERFECT_FIT] - 1, SECTIONS.PERFECT_FIT);
        }
    };

    onNextStep = () => {
        sessionStore.incCurrentStep();
    };

    toggleDisabled = disabled => {
        const { final } = this.state;
        // const mobile = viewportStore.isMobile || viewportStore.isMobileL;
        console.log('toggleDisabled');
        if (!final) {
            console.log('SHOULD DISABLED', disabled);
            this.setState({ disabled: !!disabled });
        }
    };

    scrollToQuestionView = () => {
        const mobile = viewportStore.isMobile || viewportStore.isMobileL;
        const buttonGroup = document.getElementsByClassName('button-group')[0];
        if (mobile && buttonGroup) {
            setTimeout(() => {
                // buttonGroup.scrollIntoView({ behavior: 'smooth' });
                window.scrollTo({ top: documentOffsetTop(buttonGroup), behavior: 'smooth' });
            }, 100);
        }
    };

    onFocusHandler = () => {
        this.scrollToQuestionView();
        this.toggleDisabled(true);
    };

    render() {
        const { routes, params } = sessionStore;
        const { final, disabled, initial, isPrevious, finalHeight } = this.state;
        const tops = params[PARAMS.TOPS];
        const bottoms = params[PARAMS.BOTTOMS];
        const favoriteBodyPart = params[PARAMS.FAVORITE_BODY_PART];
        const frustratingFor = params[PARAMS.FRUSTRATING_FOR];
        const mobile = viewportStore.isMobile || viewportStore.isMobileL;

        const questions = [
            <QuestionScales
                text="What size scale are you most familiar with when it comes to finding jeans? Pick one:"
                firstScaleText="23, 24, 25 ... 49, 50+"
                secondScaleText="0, 2, 4 ... 38, 40+"
                selected={params?.[PARAMS.SCALE]}
                onFirstScaleClick={() => {
                    sessionStore.setParam(PARAMS.SCALE, "23, 24, 25 ... 49, 50+");
                    if (routes[SECTIONS.PERFECT_FIT] === 0) {
                        if (mobile) {
                            if (!isPrevious) {
                                setTimeout(this.onNext, 100);
                            }
                        } else {
                            setTimeout(this.onNext, 100);

                        }
                    }
                }}
                onSecondScaleClick={() => {
                    sessionStore.setParam(PARAMS.SCALE, "0, 2, 4 ... 38, 40+");
                    if (routes[SECTIONS.PERFECT_FIT] === 0) {
                        if (mobile) {
                            if (!isPrevious) {
                                setTimeout(this.onNext, 100);
                            }

                        } else {
                            setTimeout(this.onNext, 100);
                        }
                    }
                }}
                disabled={final ? false : routes[SECTIONS.PERFECT_FIT] !== 0}
            />,
            <QuestionBorderedSelect
                scale={params[PARAMS.SCALE] === "23, 24, 25 ... 49, 50+" ? BRANDS_SIZES : BRANDS_SIZES_ALT}
                text={`What two sizes (${params[PARAMS.SCALE]}) do you tend to wear most often in jeans?`}
                setSize={(name, size) => {
                    sessionStore.setParam(name, size);
                    this.toggleDisabled(false);
                }}
                disabled={final ? false : routes[SECTIONS.PERFECT_FIT] !== 1}
                mainSize={params[PARAMS.MAIN_SIZE]}
                alsoBuySize={params[PARAMS.ALSO_BUY_SIZE]}
                onFocus={this.onFocusHandler}
                afterChange={() => {
                    if (routes[SECTIONS.PERFECT_FIT] === 1) {
                        if (mobile) {
                            if (!isPrevious) {
                                this.onNext();
                            }

                            brandsSizesAlt(' ', params[PARAMS.MAIN_SIZE], params[PARAMS.ALSO_BUY_SIZE], params[PARAMS.SCALE] === "23, 24, 25 ... 49, 50+" ? 'alt' : 'us');
                        } else {
                            this.onNext();
                            brandsSizesAlt(' ', params[PARAMS.MAIN_SIZE], params[PARAMS.ALSO_BUY_SIZE], params[PARAMS.SCALE] === "23, 24, 25 ... 49, 50+" ? 'alt' : 'us');

                        }
                    }
                }}
            />,
            <QuestionAnimatedSelect
                value={tops}
                firstText="When shopping, I generally prefer tops that are "
                secondText="."
                disabled={final ? false : routes[SECTIONS.PERFECT_FIT] !== 2}
                onChange={text => {
                    this.toggleDisabled(true);
                    sessionStore.setParam(PARAMS.TOPS, text);
                    sendAnswer('When shopping I prefer tops that are ', text);
                }}
                afterChange={() => {
                    if (routes[SECTIONS.PERFECT_FIT] === 2/* && !mobile */) {
                        if (mobile) {
                            if (!isPrevious) {
                                this.onNext();
                            }
                        } else {
                            this.onNext();
                        }
                    }
                }}
                options={TOPS_BOTTOMS_OPTIONS}
                onFocus={this.onFocusHandler}
                final={final}
            />,
            <QuestionAnimatedSelect
                value={bottoms}
                firstText="And bottoms that are "
                secondText="."
                disabled={final ? false : routes[SECTIONS.PERFECT_FIT] !== 3}
                onFocus={this.onFocusHandler}
                onChange={text => {
                    sessionStore.setParam(PARAMS.BOTTOMS, text);
                    sendAnswer('And bottoms that are ', text);
                    this.toggleDisabled(false);
                }}
                afterChange={() => {
                    if (routes[SECTIONS.PERFECT_FIT] === 3 /* && !mobile */) {
                        if (mobile) {
                            if (!isPrevious) {
                                this.onNext();
                            }
                        } else {
                            this.onNext();
                        }
                    }
                }}
                options={TOPS_BOTTOMS_OPTIONS}
                final={final}
            />,
            <QuestionAnimatedSelect
                value={favoriteBodyPart}
                firstText="My favorite part of my body to highlight is my "
                secondText="."
                disabled={final ? false : routes[SECTIONS.PERFECT_FIT] !== 4}
                onChange={text => {
                    sessionStore.setParam(PARAMS.FAVORITE_BODY_PART, text);
                    sendAnswer('My favorite part of my body to highlight is my ', text);
                    this.toggleDisabled(false);
                }}
                afterChange={() => {
                    if (routes[SECTIONS.PERFECT_FIT] === 4 /* && !mobile */) {
                        if (mobile) {
                            if (!isPrevious) {
                                this.onNext();
                            }
                        } else {
                            this.onNext();
                        }
                    }
                }}
                onFocus={this.onFocusHandler}
                options={BODY_PART_OPTIONS}
                final={final}
            />,
            <QuestioIcons
                value={frustratingFor}
                onFocus={() => {
                    if (mobile && final) {
                        setTimeout(this.setFinalContentHeight, 500);
                    }
                }}
                firstText="I find it most frustrating to shop for "
                secondText="."
                readyState={frustratingFor}
                checks={checks}
                didUpdate={() => {
                    if (mobile && routes[SECTIONS.PERFECT_FIT] === 5) {
                        this.onFocusHandler();
                    }
                }}
                didUpdateParams={[routes[SECTIONS.PERFECT_FIT]]}
                onCheck={text => {
                    sessionStore.setParam(PARAMS.FRUSTRATING_FOR, text);
                    sendAnswer('I find it most frustrating to shop for ', text);
                    this.toggleDisabled(false);
                }}
                afterCheck={() => {
                    // if (!mobile) {
                    if (mobile) {
                        if (!isPrevious) {
                            this.onNext();
                            this.setState({ final: true });
                        }
                        if (final) {
                            this.setFinalContentHeight();
                        }
                    } else {
                        this.onNext();
                        this.setState({ final: true });
                    }
                    // }
                }}
            />,
            <div style={{ display: 'flex', justifyContent: 'center' }}>
                <NextButton text="Next" onClick={this.onNextStep} />
            </div>
        ];
        console.log('FOCUSED', disabled);

        const setMobileHeight = () => {
            const focused = disabled;
            if (final) {
                return 'auto';
            }
            if (sessionStore.routes[SECTIONS.PERFECT_FIT] === 0 || sessionStore.routes[SECTIONS.PERFECT_FIT] === 1) {
                return '800px';
            }
            if (sessionStore.routes[SECTIONS.PERFECT_FIT] === 4) {
                if (focused) {
                    return '780px';
                }
            }
            if (sessionStore.routes[SECTIONS.PERFECT_FIT] === 5) {
                return '780px';
            }

            if (focused) {
                if (sessionStore.routes[SECTIONS.PERFECT_FIT] === 3) {
                    return '700px';
                }

                return '500px';
            }

            return '130px';
        };

        console.log('setMobileHeight', setMobileHeight());

        return (
            <div className="perfect-fit-section">
                {mobile && !final && (
                    <div className="button-group">
                        {sessionStore.routes[SECTIONS.PERFECT_FIT] !== 0 ? (
                            <InlineButton
                                text="Previous"
                                onClick={() => {
                                    this.toggleDisabled(false);
                                    this.onPrev();
                                    // this.scrollToQuestionView();
                                }}
                            />
                        ) : (
                                <div />
                            )}
                        {isPrevious && this.canScrollNext() ? (
                            <InlineButton
                                text="Next"
                                toRight
                                onClick={() => {
                                    this.toggleDisabled(false);
                                    this.onNext();
                                    // this.scrollToQuestionView();
                                }}
                            />
                        ) : (
                                <div />
                            )}
                    </div>
                )}
                {mobile ? (
                    <div style={{ height: setMobileHeight(), overflow: 'hidden' }}>
                        <div className={`body-content ${final ? 'final' : ''}`} style={{ height: finalHeight }}>
                            <QuestionScroller
                                final={final}
                                current={sessionStore.routes[SECTIONS.PERFECT_FIT]}
                                data={questions}
                                onNext={this.onNext}
                                onPrev={this.onPrev}
                                mobile={mobile}
                                onClick={this.onClick}
                                disabled={mobile || disabled || initial}
                                firstCup={10}
                                ref={el => {
                                    this.scroller = el;
                                }}
                            />
                        </div>
                    </div>
                ) : (
                        <div className={`body-content ${final ? 'final' : ''}`}>
                            <QuestionScroller
                                final={final}
                                current={sessionStore.routes[SECTIONS.PERFECT_FIT]}
                                data={questions}
                                onNext={this.onNext}
                                onPrev={this.onPrev}
                                mobile={mobile}
                                onClick={this.onClick}
                                disabled={mobile || disabled || initial}
                                firstCup={10}
                                ref={el => {
                                    this.scroller = el;
                                }}
                            />
                        </div>
                    )}
            </div>
        );
    }
}

export default PerfectFitSection;
