import React, { useState, useEffect } from 'react';
import { observer } from 'mobx-react';
import ClothesCheckBox from '../molecules/ClothesCheckBox';
import { ReactComponent as WaistIcon } from '../../assets/icons/waist.svg';
import { ReactComponent as TShirtIcon } from '../../assets/icons/tshirt.svg';
import { ReactComponent as TagIcon } from '../../assets/icons/tag.svg';
import { ReactComponent as ShopingBagIcon } from '../../assets/icons/shoping-bag.svg';
import sessionStore from '../../stores/sessionStore';

import { PARAMS } from '../../stores/sessionStore/config';
import { sendAnswer } from '../../services/http';

const checks = [
  {
    icon: <WaistIcon />,
    text: 'Finding jeans that fit my body',
  },
  {
    icon: <TShirtIcon />,
    text: 'Finding jeans that are my style',
  },
  {
    icon: <ShopingBagIcon />,
    text: 'Finding jeans as easily as possible',
  },
  {
    icon: <TagIcon />,
    text: 'Exploring new brands I’ve never heard of',
  },
];

export default observer(() => {
  const [current, check] = useState(null);

  useEffect(() => {
    const readyState = sessionStore.params[PARAMS.CLOTHING_ONLINE];
    if (readyState) {
      const number = checks.reduce((prev, elem, index) => {
        if (elem.text === readyState) {
          return index;
        }
        return prev;
      }, -1);
      check(number);
    }
  }, []);

  const onCheck = (number) => () => {
    check(number);
    sessionStore.setParam(PARAMS.CLOTHING_ONLINE, checks[number].text);
    setTimeout(() => {
      sessionStore.incCurrentStep();
      sendAnswer('What’s most important to you when shopping for clothing online?', checks[number].text);
    }, 300);
  };

  return (
    <div className="clothes-container">
      <div className="clothes-left">
        {[0, 1].map((num) => {
          const card = checks[num];
          const { text, icon } = card;
          return (
            <div className="clothes-card" key={num}>
              <ClothesCheckBox icon={icon} checked={num === current} onClick={onCheck(num)} />
              <p className="clothes-text">{text}</p>
            </div>
          );
        })}
      </div>
      <div className="clothes-right">
        {[2, 3].map((num) => {
          const card = checks[num];
          const { text, icon } = card;
          return (
            <div className="clothes-card" key={num}>
              <ClothesCheckBox icon={icon} checked={num === current} onClick={onCheck(num)} />
              <p className="clothes-text">{text}</p>
            </div>
          );
        })}
      </div>
    </div>
  );
});
