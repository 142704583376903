import React from 'react';

export default () => (
  <>
    <path
      className="prefix__cls-2"
      d="M397.471,260.638a.106.106,0,0,1-.029.059c-.427.868-.721,1.4-.794,1.515v-.029c.044-.28.264-1.5.485-3.177v-.015a43.821,43.821,0,0,0,.353-9.369c-.721-6.663-2.677-9.826-3.383-10.767-.044-.074-.088-.133-.118-.177-.058-.059-.088-.117-.117-.147a.11.11,0,0,1-.015.044v.015c-.147,1.1-2.765,18.857-18.548,22.063a52.244,52.244,0,0,1-16.385.5,43.753,43.753,0,0,1-15.562-4.809c-13.312-7.281-23.4-23.549-16.577-57.262.015-.058.03-.132.044-.191.03-.132.044-.235.059-.338.015-.044.015-.074.03-.118a1.545,1.545,0,0,0,.014-.632c-.75-4.531-26.093,10.561-25.284,26.225a7.192,7.192,0,0,0,.073.957c1.1,9.192-1.676,22.43-1.676,22.43s-52.143,5.031-44.612-43.876c2.456-15.959,3.5-31.506,3.5-31.506s-7.06-10.487-5.84-21.254c1.9-16.694,13.15-26.829,46.024-25.02,3.692.206,7.075.339,10.193.456,11.6.427,19.563.456,25.976,1.765a.182.182,0,0,1,.088.015,30.523,30.523,0,0,1,6.207,1.912,28.16,28.16,0,0,1,2.751,1.368q.441.243.882.53c.4.235.795.5,1.192.779q.948.661,1.9,1.412c.309.25.633.515.941.78.4.353.8.706,1.192,1.074s.794.764,1.206,1.162c.794.794,1.618,1.662,2.456,2.588a20.489,20.489,0,0,1,3.825,6.149q.33.882.573,1.765c.088.308.162.617.236.912.117.5.205,1.014.279,1.529.029.177.059.353.088.53.074.573.133,1.132.162,1.706.015.294.029.618.029.927a38.99,38.99,0,0,1-.279,5.545c-.147,1.206-.324,2.427-.485,3.633-.353,2.368-.736,4.707-.971,7a26.367,26.367,0,0,0,.176,7.472,15.446,15.446,0,0,0,.486,1.971,15.793,15.793,0,0,0,4.545,7l2.6,2.382c13.032,11.856,26.858,23.74,33.86,39.832a.788.788,0,0,1,.073.161C405.311,242.12,399.722,256.02,397.471,260.638Z"
    />
    <path
      className="prefix__cls-1"
      d="M272.129,147.814s-5.618,9.354-3.894,17.8,6.764,13.062,5.175,17.361c-1.125,3.041-5.418,7.426-7.768,10.249-.831,1,4.944,6.279,4.944,6.279s-3.716,4.334-1.909,6.161a12.585,12.585,0,0,0,4.171,2.674s-2.507,3.47-1.7,4.524,6.147,2.216,7.565,4.547,2.28,12.943,8.73,12.136c4.676-.586,19.966-6.574,30.338-17.657s9.094-14.115,9.094-14.115,4.249,2.055,9.329-3.677c5.883-6.639,2.328-19.489-4.045-19.244-5.635.217-7.11,6.889-7.11,6.889s-7.6-3.537-12.132-7.305-7.646-10.674-7.646-10.674A57.427,57.427,0,0,1,287,158.5,52.1,52.1,0,0,1,272.129,147.814Z"
    />
    <path className="prefix__cls-2" d="M274.241,168.516l2.112,4.3s5.868-2.778,11.262-2.99,14.1,1.672,14.1,1.672a29.333,29.333,0,0,0-16.016-5.308C276.062,165.9,274.241,168.516,274.241,168.516Z" />
    <path
      className="prefix__cls-2"
      d="M280.746,182.75s3.239,2.447,10.33-.482a65.608,65.608,0,0,0,11.763-6.242s-8.148,7.162-13.014,8.9-6.354,1.585-6.354,1.585l.459-1.766a7.563,7.563,0,0,1-3.345,2.171l-1.966.538,1.4-2.339-3.5,1.8Z"
    />
    <path
      className="prefix__cls-5"
      d="M397.471,260.638a.106.106,0,0,1-.029.059,16.049,16.049,0,0,0-.794,1.515v-.029a16.55,16.55,0,0,0,.485-3.177v-.015a43.821,43.821,0,0,0,.353-9.369c-.721-6.663-2.677-9.826-3.383-10.767-.044-.074-.088-.133-.118-.177-.058-.059-.088-.117-.117-.147l-.03-.029a.251.251,0,0,0,.015.073v.015c-.147,1.1-2.765,18.857-18.548,22.063a52.244,52.244,0,0,1-16.385.5,43.753,43.753,0,0,1-15.562-4.809c5.339-9.326,10.222-20.946,10.664-32.624.838-21.549-5.987-30.9-15.136-41.288l-.014-.015a15.116,15.116,0,0,0-.618-2.235l-.015-.015c-.088-.25-.176-.471-.279-.706s-.206-.441-.324-.662c-.059-.118-.117-.235-.176-.338s-.118-.221-.191-.324c-.03-.044-.059-.1-.089-.147l-.264-.4a6.883,6.883,0,0,0-.765-.927,5.719,5.719,0,0,0-.412-.412c-.133-.117-.28-.235-.427-.353a3.359,3.359,0,0,0-.529-.353,1.394,1.394,0,0,0-.324-.176,3.077,3.077,0,0,0-.323-.147,4.144,4.144,0,0,0-1.986-.4c-8.649-10.017-23.019-48.613-55.629-37.728-2.279.765,12.194-8.708,32.786-10.885a73.306,73.306,0,0,1,25.976,1.765.182.182,0,0,1,.088.015c2.059.53,4.133,1.162,6.207,1.912.133.044.265.1.4.147.795.368,1.574.765,2.354,1.221q.441.243.882.53c.4.235.795.5,1.192.779q.948.661,1.9,1.412c.309.25.633.515.941.78.4.353.8.706,1.192,1.074s.794.764,1.206,1.162c.809.794,1.618,1.662,2.456,2.588a20.489,20.489,0,0,1,3.825,6.149q.33.882.573,1.765c.088.308.162.617.236.912.117.5.205,1.014.279,1.529.029.177.059.353.088.53.074.573.133,1.132.162,1.706.015.294.029.618.029.927a38.99,38.99,0,0,1-.279,5.545c-.147,1.206-.324,2.427-.485,3.633-.368,2.368-.75,4.707-.971,7a26.367,26.367,0,0,0,.176,7.472,15.446,15.446,0,0,0,.486,1.971,15.793,15.793,0,0,0,4.545,7l2.6,2.382c12.944,11.9,26.138,24.064,33.86,39.832a.788.788,0,0,1,.073.161C405.311,242.12,399.722,256.02,397.471,260.638Z"
    />
  </>
);

