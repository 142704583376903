import React from 'react';
import { observer } from 'mobx-react';
import Header from '../molecules/Header';
import sessionStore from '../../stores/sessionStore';
import { SECTIONS } from '../../stores/sessionStore/config';

export default observer(({ children, titleRef, headerRef }) => {
  const { routes, title } = sessionStore;
  return (
    <div className={`main-container ${routes[SECTIONS.MAIN] === 0 ? 'transparent' : ''}`}>
      <Header headerRef={headerRef} />
      <div className="title-cont" ref={titleRef}>
        <h1 className="main-title">{title}</h1>
      </div>
      <div style={routes[SECTIONS.MAIN] === 0 ? { backgroundColor: 'transparent' } : {}} className="main-window">
        {children}
      </div>
    </div>
  );
});
