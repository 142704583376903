import React from 'react';

export default () => (
  <>
    <path
      className="prefix__cls-2"
      d="M301.763,221.307c.174,2.093.2,4.188.241,6.287,0,2.1-.062,4.193-.154,6.293-.073,2.1-.289,4.195-.553,6.288a53.528,53.528,0,0,1-1.13,6.269,18.72,18.72,0,0,1-1.06,3.087,10.7,10.7,0,0,1-.81,1.5,6.724,6.724,0,0,1-.541.719c-.192.227-.392.4-.587.6a26.092,26.092,0,0,1-5.184,3.821c-1.844,1.051-3.739,1.979-5.659,2.839a62.053,62.053,0,0,1-5.909,2.187l-.037-.093a76.789,76.789,0,0,0,11.183-5.611,27.833,27.833,0,0,0,4.95-3.79,7.193,7.193,0,0,0,1.709-2.557,21.272,21.272,0,0,0,.988-2.956,75.141,75.141,0,0,0,1.878-12.365q.516-6.243.575-12.517Z"
    />
    <path
      className="prefix__cls-2"
      d="M303.827,251.986a45.561,45.561,0,0,1,3.006,4.978q1.314,2.6,2.373,5.334a39.766,39.766,0,0,1,1.676,5.614c.443,1.887.847,3.781,1.228,5.67.342,1.895.663,3.791,1.087,5.629a18.468,18.468,0,0,0,.786,2.646c.333.8.779,1.571,1.282,1.683.528.179,1.184-.286,1.659-1.041a11.293,11.293,0,0,0,1.133-2.5,37.3,37.3,0,0,0,1.386-5.561l.1.008a32.376,32.376,0,0,1-.986,5.7,11.493,11.493,0,0,1-1.078,2.69,3.718,3.718,0,0,1-.948,1.113,1.787,1.787,0,0,1-1.417.347,2.07,2.07,0,0,1-1.209-.826,5.9,5.9,0,0,1-.716-1.273,19.309,19.309,0,0,1-.873-2.764c-.464-1.875-.814-3.77-1.186-5.659a82.734,82.734,0,0,0-2.663-11.222,61.2,61.2,0,0,0-4.726-10.511Z"
    />
    <path className="prefix__cls-2" d="M321.84,258.4a33.747,33.747,0,0,1,1.977-7.818,40.14,40.14,0,0,1,1.573-3.694l.916-1.8c.363-.579.671-1.177,1.082-1.738l.094.035-2.727,7.541-2.821,7.508Z" />
    <path
      className="prefix__cls-3"
      d="M333.383,197.909c-.687,1.057-1.411,2.072-2.072,3.134-.7,1.026-1.391,2.062-2.047,3.117-1.33,2.093-2.693,4.157-3.866,6.331-1.293,2.09-2.37,4.338-3.615,6.509l-1.846,3.26c-.654,1.069-1.265,2.158-1.89,3.244l-.094-.031c.927-2.332,2.044-4.567,3.135-6.817,1.112-2.238,2.2-4.493,3.445-6.665s2.636-4.256,4.1-6.285a72.881,72.881,0,0,1,4.678-5.864Z"
    />
    <path
      className="prefix__cls-3"
      d="M318.04,218.448q1.993,1.833,3.786,3.79l1.8,1.937c.589.645,1.181,1.292,1.79,1.857,1.214,1.155,2.641,2.231,3.968,3.394l2.028,1.729c.647.621,1.332,1.191,1.963,1.844l-.055.083q-2.221-1.477-4.391-3.028a46.383,46.383,0,0,1-4.2-3.3,47.347,47.347,0,0,1-3.514-4.023q-1.667-2.082-3.253-4.227Z"
    />
    <path
      className="prefix__cls-3"
      d="M328.933,233q1.881-1.913,3.873-3.62c1.309-1.161,2.668-2.264,3.92-3.416,1.258-1.124,2.409-2.408,3.615-3.662.6-.629,1.188-1.279,1.771-1.934.628-.623,1.2-1.292,1.841-1.918l.084.055q-1.45,2.208-3.09,4.281A34.53,34.53,0,0,1,337.4,226.7c-1.314,1.178-2.711,2.251-4.118,3.307s-2.842,2.075-4.291,3.073Z"
    />
    <path
      className="prefix__cls-3"
      d="M329,199.35c.878.726,1.666,1.532,2.493,2.306l2.324,2.459c.777.82,1.463,1.713,2.186,2.582.706.88,1.434,1.75,2.083,2.69a32.459,32.459,0,0,1,1.795,2.939c.549.992,1.083,1.984,1.542,3.017s.951,2.042,1.4,3.078c.411,1.054.863,2.085,1.22,3.165l-.089.046q-1.569-2.976-3.189-5.927a55.266,55.266,0,0,0-3.479-5.746c-2.622-3.636-5.479-7.1-8.358-10.544Z"
    />
    <path className="prefix__cls-3" d="M330.264,193.573a9.7,9.7,0,0,1,.933,2.353,16.645,16.645,0,0,1,.459,2.406,16.93,16.93,0,0,1,.087,2.449,9.8,9.8,0,0,1-.383,2.5l-.1.011-.6-4.848-.5-4.861Z" />
    <polygon className="prefix__cls-4" points="331.649 204.454 321.496 219.836 331.107 229.126 341.802 218.795 331.649 204.454" />
    <path
      className="prefix__cls-2"
      d="M276.49,137.061a30.293,30.293,0,0,1,10.91-5.2,31.362,31.362,0,0,1,12.151-.564,37.056,37.056,0,0,1,11.569,3.948,32.822,32.822,0,0,1,9.533,7.733,45.844,45.844,0,0,1,6.361,10.481c1.742,3.622,3.077,7.579,5.841,10.135,2.963,2.575,6.308,4.922,9.491,7.394,3.212,2.457,6.388,4.975,9.468,7.615a111.252,111.252,0,0,1,16.774,17.649c4.789,6.551,8.859,13.924,10.107,22.145a34.222,34.222,0,0,1-.595,12.254,47.186,47.186,0,0,1-4.306,11.422,77.657,77.657,0,0,1-14.839,19.116l-.07-.071a77.364,77.364,0,0,0,14.44-19.284,46.924,46.924,0,0,0,4.136-11.321,33.515,33.515,0,0,0,.467-12,30.656,30.656,0,0,0-1.454-5.85,50.941,50.941,0,0,0-2.288-5.588,65.127,65.127,0,0,0-6.328-10.278,110.25,110.25,0,0,0-16.7-17.434c-3.054-2.632-6.2-5.153-9.4-7.614-3.176-2.487-6.476-4.814-9.523-7.48a16.994,16.994,0,0,1-3.48-4.945c-.892-1.815-1.7-3.666-2.532-5.5a46.821,46.821,0,0,0-6.174-10.347,34.348,34.348,0,0,0-20.588-11.656,30.944,30.944,0,0,0-11.993.349,30.117,30.117,0,0,0-10.919,4.971Z"
    />
  </>
);
