import React from 'react';
import { observer } from 'mobx-react';
import TransformSpringPath from '../molecules/TransformSpringPath';
import characterStore from '../../stores/characterStore';

export default observer(() => {
  const { left, right } = characterStore.shoulders;
  return (
    <>
      <TransformSpringPath
        scaleX={left.scaleX}
        skewY={left.skewY}
        skewX={left.skewX}
        scaleY={left.scaleY}
        translateX={left.translateX}
        translateY={left.translateY}
        className="prefix__cls-2"
        d="M295.029,268.486a50.639,50.639,0,0,1-5.3-1.339l.017,0a116.335,116.335,0,0,0-11.553-1.827,96.667,96.667,0,0,0-11.668-.615,51.077,51.077,0,0,0-11.545,1.322,19.827,19.827,0,0,0-9.991,5.386,37.361,37.361,0,0,0-6.5,9.519,82.07,82.07,0,0,0-4.357,10.792,153.408,153.408,0,0,0-5.513,22.713l-.1-.012a118.926,118.926,0,0,1,4.7-23A74.29,74.29,0,0,1,237.6,280.49a36.391,36.391,0,0,1,6.782-9.681,18.392,18.392,0,0,1,4.825-3.5,25.924,25.924,0,0,1,5.6-1.979,51.668,51.668,0,0,1,11.713-1.227,109.1,109.1,0,0,1,23.3,2.723l.009,0,.009,0a50.533,50.533,0,0,0,5.211,1.559Z"
      />
      <TransformSpringPath
        scaleX={right.scaleX}
        skewY={right.skewY}
        skewX={right.skewX}
        scaleY={right.scaleY}
        translateX={right.translateX}
        translateY={right.translateY}
        className="prefix__cls-2"
        d="M332.241,267.851a50.477,50.477,0,0,0,5.212-1.559l.009,0,.008,0a109.04,109.04,0,0,1,23.3-2.724,51.662,51.662,0,0,1,11.713,1.227,25.924,25.924,0,0,1,5.6,1.979,18.4,18.4,0,0,1,4.826,3.5,36.437,36.437,0,0,1,6.782,9.681,74.175,74.175,0,0,1,4.382,10.943,118.771,118.771,0,0,1,4.7,23l-.1.011a153.689,153.689,0,0,0-5.513-22.713A82.264,82.264,0,0,0,388.8,280.4a37.387,37.387,0,0,0-6.5-9.519,19.839,19.839,0,0,0-9.991-5.386,51.028,51.028,0,0,0-11.546-1.321,96.436,96.436,0,0,0-11.667.615,116.352,116.352,0,0,0-11.554,1.826l.017,0a50.514,50.514,0,0,1-5.3,1.339Z"
      />
    </>
  );
});
