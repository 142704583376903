export default evt => {
    const path = (evt.composedPath && evt.composedPath()) || evt.path;
    const {target} = evt;

    if (path != null) {
        return (path.indexOf(window) < 0) ? path.concat(window) : path;
    }

    if (target === window) {
        return [window];
    }

    function getParents(node, memo) {
        const newMemo = memo || [];
        const {parentNode} = node;
        if (!parentNode) {
            return newMemo;
        } 
        return getParents(parentNode, newMemo.concat(parentNode));
    }

    return [target].concat(getParents(target), window);
};