import React from 'react';
import { ReactComponent as CheckedIcon } from '../../assets/icons/checked.svg';

export default ({ checked, onClick, icon }) => {
  return (
    <div role="button" className={`check-box ${checked ? 'checked' : ''}`} onClick={onClick} onKeyDown={onClick} tabIndex={0}>
      <div className="angle-icon">{checked && <CheckedIcon />}</div>
      <div className="check-box-icon">{icon}</div>
    </div>
  );
};
