import React from 'react';
import classNames from 'classnames';
import { ReactComponent as Arrow } from '../../assets/icons/arrow.svg';

export default ({ onClick, text, toRight }) => {
  const className = classNames('prev-button', { toRight });
  return (
    <div className={className} onClick={onClick} onKeyDown={onClick} role="button" tabIndex={0}>
      {toRight ? (
        <>
          <span>{text}</span> <Arrow />
        </>
      ) : (
        <>
          <Arrow /> <span>{text}</span>
        </>
      )}
    </div>
  );
};
