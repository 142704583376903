import { SORTED_SIZE_TYPES } from './config';
import { sizeFactors, DIRECTION_TYPES, factorsMerge } from '../../utils/sizeFactors';

const allSizeFactors = (() => {
  const arr = SORTED_SIZE_TYPES;
  // scaleX //
  const maxScaleX = {
    left: 0.793923,
    right: 0.833572
  };
  const minScaleX = {
    left: 1.02173,
    right: 1.04227
  };
  const scaleX = sizeFactors(arr, maxScaleX, minScaleX, 'scaleX', DIRECTION_TYPES.LPRP_to_LMRM, true);
  // skewY //
  const maxSkewY = {
    left: -0.0647795,
    right: 0.0529176
  };
  const minSkewY = {
    left: 0.0856277,
    right: -0.0602134
  };
  const skewY = sizeFactors(arr, maxSkewY, minSkewY, 'skewY', DIRECTION_TYPES.LPRM_to_LMRP);
  // skewX //
  const maxSkewX = {
    left: 0.0620208,
    right: -0.0441725
  };
  const minSkewX = {
    left: -0.0878109,
    right: 0.062873
  };
  const skewX = sizeFactors(arr, maxSkewX, minSkewX, 'skewX', DIRECTION_TYPES.LMRP_to_LPRM);
  // scaleY //
  const maxScaleY = {
    left: 0.99775,
    right: 0.998599
  };
  const minScaleY = {
    left: 0.996327,
    right: 0.998186
  };
  const scaleY = sizeFactors(arr, maxScaleY, minScaleY, 'scaleY', DIRECTION_TYPES.LPRP_to_LPRP);
  // translateX //
  const maxTranslateX = {
    left: 16.0779,
    right: 88.7423
  };
  const minTranslateX = {
    left: 36.5208,
    right: -44.8947
  };

  const translateX = sizeFactors(arr, maxTranslateX, minTranslateX, 'translateX', DIRECTION_TYPES.LPRP_to_LPRM); // direction - not standard behavior

  // translateY //
  const maxTranslateY = {
    left: 17.8856,
    right: -18.7997
  };
  const minTranslateY = {
    left: -20.3721,
    right: 23.0666
  };
  const translateY = sizeFactors(arr, maxTranslateY, minTranslateY, 'translateY', DIRECTION_TYPES.LMRP_to_LPRM); // direction - not standard behavior
  const result = factorsMerge(scaleX, skewY, skewX, scaleY, translateX, translateY);

  return result;
})();

export const waistSizeFactors = allSizeFactors;
