import React from 'react';
import { animated } from 'react-spring';

import { ReactComponent as CheckedIcon } from '../../assets/icons/checked.svg';

export default ({ value, onClick, checked, height }) => {
  return (
    <animated.div
      className={`option ${checked ? 'checked' : ''}`}
      role="button"
      onClick={onClick}
      onKeyDown={onClick}
      tabIndex={0}
      style={{ height }}
    >
      <span className="option-text">{value}</span>
      <div className="option-icon">{checked && <CheckedIcon />}</div>
    </animated.div>
  );
};
