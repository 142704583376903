/* eslint-disable */
import React, { Component } from 'react';
import { observer } from 'mobx-react';
import Character from '../../character/index';
import sessionStore from '../../../stores/sessionStore';
import {
  SHORT_OPTIONS,
  SHORT_VALUES,
  SECTIONS,
  BODY_QUESTIONS,
  PARAMS,
  VALUES,
  BODY_TYPE_OPTIONS,
  TIMEOUTS
} from '../../../stores/sessionStore/config';
import { FOCUS_RECT_CONFIG, BAND_SIZES, CUP_SIZES } from '../../../stores/characterStore/config';
import characterStore from '../../../stores/characterStore';
import QuestionScroller from '../../organisms/Scroller';
import QuestionAnimatedSelect from '../../templates/questionTemplates/QuestionAnimatedSelect';
import QuestionInput from '../../templates/questionTemplates/QuestionInput';
import QuestionSelect from '../../templates/questionTemplates/QuestionSelect';
import NextButton from '../../atoms/NextButton';
import viewportStore from '../../../stores/viewportStore';
import InlineButton from '../../atoms/InlineButton';
import documentOffsetTop from '../../../utils/documentOffsetTop';
import { sendAnswer } from '../../../services/http';

const bandSizes = BAND_SIZES.map(el => ({ name: el }));
const capSizes = CUP_SIZES.map(el => ({ name: el }));

@observer
class BodySection extends Component {
  constructor(props) {
    super(props);
    this.state = {
      initial: true,
      final: false,
      focus: [false, false],
      qestionSelectFocused: false,
      firstFocus: true,
      isPrevious: false,
      finalHeight: ''
    };
    this.scroller = null;
  }

  componentDidMount() {
    setTimeout(() => {
      this.setState({ initial: false });
    }, 100);
    let currentQuestion = sessionStore.routes[SECTIONS.YOUR_BODY];
    if (currentQuestion > 6) {
      this.setState({ final: true });
    } else if (this.canScrollNext(currentQuestion)) {
      this.setState({ isPrevious: true });
    }
    document.addEventListener('keydown', this.onEnterHandler);
  }

  componentDidUpdate() {
    const { final, finalHeight } = this.state;
    if (final && !finalHeight) {
      setTimeout(() => {
        const finalContent = document.getElementsByClassName('scroller-content')[0];
        this.setState({ finalHeight: finalContent.getBoundingClientRect().height });
      }, 100);
    }
  }
  componentWillUnmount() {
    document.removeEventListener('keydown', this.onEnterHandler);
  }
  onEnterHandler = event => {
    const { final } = this.state;
    const currentRoute = sessionStore.routes[SECTIONS.YOUR_BODY];
    if (event.key === 'Enter' && !final && this.canScrollNext()) {
      sessionStore.setQuestion(currentRoute + 1, SECTIONS.YOUR_BODY);
    }
  };
  onClick = num => {
    sessionStore.setQuestion(num, SECTIONS.YOUR_BODY);
  };

  setCharacterRect = () => {
    switch (sessionStore.routes[SECTIONS.YOUR_BODY]) {
      case 1: {
        characterStore.setFocusRect(FOCUS_RECT_CONFIG.SHOULDERS);
        break;
      }
      case 2: {
        characterStore.setFocusRect(FOCUS_RECT_CONFIG.CHEST_SIZE);
        break;
      }
      case 3: {
        characterStore.setFocusRect(FOCUS_RECT_CONFIG.CHEST);
        break;
      }
      case 4: {
        characterStore.setFocusRect(FOCUS_RECT_CONFIG.WAIST);
        break;
      }
      case 5: {
        characterStore.setFocusRect(FOCUS_RECT_CONFIG.LEGS);
        break;
      }
      default: {
        characterStore.setFocusRect(FOCUS_RECT_CONFIG.NONE);
      }
    }
  };

  canScrollNext = () => {
    const currentRoute = sessionStore.routes[SECTIONS.YOUR_BODY];
    if (!BODY_QUESTIONS[currentRoute]) return;
    const permission = Object.values(BODY_QUESTIONS[currentRoute]).every(key => {
      return sessionStore.params[key];
    });
    return permission;
  };

  onNext = () => {
    const currentQuestion = sessionStore.routes[SECTIONS.YOUR_BODY];
    const { final } = this.state;
    this.setState({ firstFocus: true });

    if (currentQuestion === 6 && this.canScrollNext()) {
      this.setState({ final: true });
      sessionStore.setQuestion(currentQuestion + 1, SECTIONS.YOUR_BODY);
    }
    if (this.canScrollNext() && !final) {
      sessionStore.setQuestion(currentQuestion + 1, SECTIONS.YOUR_BODY);
    }
    if (this.canScrollNext(currentQuestion)) {
      this.setState({ isPrevious: true });
    } else {
      this.setState({ isPrevious: false });
    }
  };

  onPrev = () => {
    const { final } = this.state;
    if (sessionStore.routes[SECTIONS.YOUR_BODY] > 0 && !final) {
      this.setState({ isPrevious: true });
      sessionStore.setQuestion(sessionStore.routes[SECTIONS.YOUR_BODY] - 1, SECTIONS.YOUR_BODY);
    }
  };

  onNextStep = () => {
    sessionStore.sendBodyAnswers();
    sessionStore.incCurrentStep();
  };

  toggleFocus = focused => {
    this.setState({ qestionSelectFocused: focused });
  };

  scrollToQuestionView = (timeout = 100, direction) => {
    const mobile = viewportStore.isMobile || viewportStore.isMobileL;
    const targetElement = document.getElementsByClassName(direction ? 'body-character' : 'button-group')[0];

    if (mobile && targetElement) {
      setTimeout(() => {
        window.scrollTo({ top: documentOffsetTop(targetElement), behavior: 'smooth' });
      }, timeout);
    }
  };

  onFocusHandler = () => {
    this.toggleFocus(true);
    this.scrollToQuestionView();
  };

  onInputFocusHandler = () => {
    const { firstFocus } = this.state;
    this.toggleFocus(true);
    if (firstFocus) {
      this.scrollToQuestionView(500);
      //   console.log('first focus');
      this.setState({ firstFocus: false });
    }
  };

  render() {
    const { routes, params } = sessionStore;
    const { final, focus, initial, qestionSelectFocused, isPrevious, finalHeight } = this.state;
    const shoulders = params[PARAMS.SHOULDERS];
    const chest = params[PARAMS.CHEST];
    const waist = params[PARAMS.WAIST];
    const legs = params[PARAMS.LEGS];
    const feet = params[PARAMS.FEET];
    const inches = params[PARAMS.INCHES];
    const lbs = params[PARAMS.LBS];
    const bodyType = params[PARAMS.BODY_TYPE];
    const mobile = viewportStore.isMobile || viewportStore.isMobileL;

    this.setCharacterRect();

    const questions = [
      <QuestionInput
        disabled={final ? false : routes[SECTIONS.YOUR_BODY] !== 0}
        afterChangeTimeout={1500}
        toNext={() => {
          this.toggleFocus(false);

          if (this.canScrollNext(0)) {
            const inches = sessionStore.params[PARAMS.INCHES];
            const feet = sessionStore.params[PARAMS.FEET];
            const lbs = params[PARAMS.LBS];

            if (inches && feet) {
              sendAnswer('height', `${feet}'${inches}''`);
            }
            if (lbs) {
              sendAnswer('weight', `${lbs}`);
            }
            ['feetInput', 'inchesInput', 'lbsInput'].forEach(inputId => {
              const input = document.getElementById(inputId);
              if (input) {
                input.blur();
              }
            });

            if (mobile) {
              this.scrollToQuestionView(100, true);
              if (!isPrevious) {
                this.onNext();
              }
            } else {
              this.onNext();
            }
          }
        }}
        onFocus={this.onInputFocusHandler}
        onChange={(name, value) => {
          sessionStore.setParam(name, value);
        }}
        lines={[
          {
            values: [
              { word: 'I am ', value: feet, placeholder: 'feet', name: PARAMS.FEET, input: true, inputId: 'feetInput' },
              {
                word: ' feet ',
                value: inches,
                placeholder: 'inches',
                name: PARAMS.INCHES,
                input: true,
                inputId: 'inchesInput'
              },
              { word: ' inches tall.', input: false }
            ],
            id: 1
          },
          {
            values: [
              {
                word: 'And I weigh ',
                value: lbs,
                placeholder: 'lbs',
                name: PARAMS.LBS,
                input: true,
                inputId: 'lbsInput'
              },
              { word: ' lbs.', input: false }
            ],
            id: 2
          }
        ]}
      />,
      <QuestionAnimatedSelect
        // afterChangeTimeout={mobile ? 300 : undefined}
        value={shoulders}
        firstText="My shoulders are "
        secondText=" than my chest."
        disabled={final ? false : routes[SECTIONS.YOUR_BODY] !== 1}
        onChange={text => {
          sessionStore.setParam(PARAMS.SHOULDERS, text);
          characterStore.setShoulders(VALUES[text]);
          this.toggleFocus(false);
        }}
        afterChange={() => {
          if (routes[SECTIONS.YOUR_BODY] === 1) {
            // !mobile ? this.onNext() : this.scrollToQuestionView(true);
            const shoulders = params[PARAMS.SHOULDERS];
            if (shoulders) {
              sendAnswer('My shoulders are ___ than my chest.', `${shoulders}`);
            }
            if (mobile) {
              this.scrollToQuestionView(100, true);
              if (!isPrevious) {
                this.onNext();
              }
            } else {
              this.onNext();
            }
          }
        }}
        onFocus={this.onFocusHandler}
        final={final}
      />,
      <QuestionSelect
        firstText="I wear a "
        secondText=" bra size."
        defaultValue={
          params[PARAMS.BAND] === 'I don’t wear a bra!' || params[PARAMS.CUP] === 'I don’t wear a bra!'
            ? 'I don’t wear a bra!'
            : undefined
        }
        onBlur={id => {
          const { focus } = this.state;
          let res = [...focus];
          res[id] = false;
          this.setState({ focus: res });
          if (this.scroller) {
            if (this.scroller.scrollerSelected) {
              this.scroller.disablePageScrolling();
            } else {
              this.scroller.enablePageScrolling();
            }
          }
        }}
        onFocus={name => {
          this.scrollToQuestionView();

          const { focus } = this.state;
          let res = [...focus];
          if (name === 'cup') {
            res[0] = true;
          } else {
            res[1] = true;
          }
          this.setState({ focus: res });
        }}
        disabled={final ? false : routes[SECTIONS.YOUR_BODY] !== 2}
        onChange={(name, text) => {
          const { focus } = this.state;
          
          let res = [...focus];
          if (text === 'I don’t wear a bra!') {
            sessionStore.setParam([PARAMS.CUP], text);
            sessionStore.setParam([PARAMS.BAND], text);
          } else {
            console.log('ELSE', text);
            sessionStore.setParam(name, text);
            if (name === PARAMS.CUP && params[PARAMS.BAND] === 'I don’t wear a bra!') {
              sessionStore.setParam(PARAMS.BAND, '');
            }
            if (name === PARAMS.BAND && params[PARAMS.CUP] === 'I don’t wear a bra!') {
              sessionStore.setParam(PARAMS.CUP, '');
            }
          }


          if (name === 'cup') {
            // console.log(text, params[PARAMS.CUP]);
            // if (params[PARAMS.CUP] !== text) {
            characterStore.setCupSizeChest(text);
            // }
            res[0] = false;
          } else {
            res[1] = false;
          }

          this.setState({ focus: res });
          if (params[PARAMS.BAND] && params[PARAMS.CUP]) {
            if (params[PARAMS.BAND] === 'I don’t wear a bra!' || params[PARAMS.CUP] === 'I don’t wear a bra!') {
              sendAnswer('Bra size', 'I don’t wear a bra!');
            } else {
              sendAnswer('Bra size', `${params[PARAMS.BAND]} ${params[PARAMS.CUP]}`);
            }

            setTimeout(
              () => {
                // !mobile ? this.onNext() : this.scrollToQuestionView(true);
                if (mobile) {
                  this.scrollToQuestionView(100, true);
                  if (!isPrevious) {
                    this.onNext();
                  }
                } else {
                  this.onNext();
                }
              },
              mobile ? 300 : TIMEOUTS.YOUR_BODY
            );
          }
        }}
        selects={[
          {
            value: params[PARAMS.BAND],
            placeholder: 'band size',
            name: PARAMS.BAND,
            options: bandSizes,
            id: 1
          },
          {
            value: params[PARAMS.CUP],
            placeholder: 'cup size',
            name: PARAMS.CUP,
            options: capSizes,
            id: 0
          }
        ]}
      />,
      <QuestionAnimatedSelect
        final={final}
        // afterChangeTimeout={mobile ? 300 : undefined}
        value={chest}
        firstText="My chest is "
        secondText=" than my waist."
        disabled={final ? false : routes[SECTIONS.YOUR_BODY] !== 3}
        onChange={text => {
          // console.log('setChest', text, chest);
          if (chest !== text) {
            sessionStore.setParam(PARAMS.CHEST, text);
            characterStore.setChestSize(VALUES[text]);
          }
          // console.log('onChange');
          this.toggleFocus(false);
        }}
        afterChange={() => {
          if (routes[SECTIONS.YOUR_BODY] === 3) {
            // !mobile ? this.onNext() : this.scrollToQuestionView(true);
            const chest = params[PARAMS.CHEST];
            if (chest) {
              sendAnswer('My chest is ___ than my waist.', `${chest}`);
            }
            if (mobile) {
              this.scrollToQuestionView(100, true);
              if (!isPrevious) {
                this.onNext();
              }
            } else {
              this.onNext();
            }
          }
        }}
        onFocus={this.onFocusHandler}
      />,
      <QuestionAnimatedSelect
        // afterChangeTimeout={mobile ? 300 : undefined}
        value={waist}
        firstText="My waist is "
        secondText=" than my hips."
        disabled={final ? false : routes[SECTIONS.YOUR_BODY] !== 4}
        onChange={text => {
          if (waist !== text) {
            characterStore.setWaistSize(VALUES[text]);
            sessionStore.setParam(PARAMS.WAIST, text);
          }
          this.toggleFocus(false);
        }}
        afterChange={() => {
          if (routes[SECTIONS.YOUR_BODY] === 4) {
            // !mobile ? this.onNext() : this.scrollToQuestionView(true);
            const waist = params[PARAMS.WAIST];
            if (waist) {
              sendAnswer('My waist is ___ than my hips.', `${waist}`);
            }
            if (mobile) {
              this.scrollToQuestionView(100, true);
              if (!isPrevious) {
                this.onNext();
              }
            } else {
              this.onNext();
            }
          }
        }}
        onFocus={this.onFocusHandler}
      />,
      <QuestionAnimatedSelect
        final={final}
        // afterChangeTimeout={mobile ? 300 : undefined}
        value={legs}
        firstText="My legs are "
        secondText=" than my torso."
        disabled={final ? false : routes[SECTIONS.YOUR_BODY] !== 5}
        onChange={text => {
          if (legs !== text) {
            characterStore.setLegs(SHORT_VALUES[text]);
            sessionStore.setParam(PARAMS.LEGS, text);
          }
          this.toggleFocus(false);
        }}
        afterChange={() => {
          if (routes[SECTIONS.YOUR_BODY] === 5) {
            // !mobile ? this.onNext() : this.scrollToQuestionView(true);
            const legs = params[PARAMS.LEGS];
            if (legs) {
              sendAnswer('My legs are ___ than my torso.', `${legs}`);
            }
            if (mobile) {
              this.scrollToQuestionView(100, true);
              if (!isPrevious) {
                this.onNext();
              }
            } else {
              this.onNext();
            }
          }
        }}
        options={SHORT_OPTIONS}
        onFocus={this.onFocusHandler}
      />,
      <QuestionAnimatedSelect
        final={final}
        // afterChangeTimeout={mobile ? 300 : undefined}
        disabled={final ? false : routes[SECTIONS.YOUR_BODY] !== 6}
        value={bodyType}
        firstText="I would describe my body as"
        secondText=" ."
        onChange={text => {
          sessionStore.setParam(PARAMS.BODY_TYPE, text);
          this.toggleFocus(false);
        }}
        afterChange={() => {
          // if (routes[SECTIONS.YOUR_BODY] === 6) {
          // if (!mobile) {
          const bodyType = params[PARAMS.BODY_TYPE];
          if (bodyType) {
            sendAnswer('I would describe my body as', `${bodyType}`);
          }
          this.setState({ final: true });
          if (mobile) {
            this.scrollToQuestionView(100, true);
            if (!isPrevious) {
              this.onNext();
            }
          } else {
            this.onNext();
          }
          // } else {
          //   this.scrollToQuestionView(true);
          // }

          // }
        }}
        options={BODY_TYPE_OPTIONS}
        onFocus={this.onFocusHandler}
      />,
      <div style={{ display: 'flex', justifyContent: 'center' }}>
        <NextButton text="That's My Body" onClick={this.onNextStep} />
      </div>
    ];
    const setMobileHeight = () => {
      const focused = qestionSelectFocused || focus.some(el => el);
      if (final) {
        return 'auto';
      }
      if (sessionStore.routes[SECTIONS.YOUR_BODY] === 0) {
        return '330px';
      }
      if (sessionStore.routes[SECTIONS.YOUR_BODY] === 2) {
        return '380px';
      }
      if ((sessionStore.routes[SECTIONS.YOUR_BODY] === 6 || sessionStore.routes[SECTIONS.YOUR_BODY] === 5) && focused) {
        return '340px';
      }
      if (focused) {
        return '480px';
      } else {
        return '120px';
      }
    };

    return (
      <div className="body-section">
        <div className="body-character">
          <div className="body-back">
            <Character />
          </div>
        </div>
        {mobile && !final && (
          <div className="button-group">
            {sessionStore.routes[SECTIONS.YOUR_BODY] !== 0 ? (
              <InlineButton
                text="Previous"
                onClick={() => {
                  // this.scrollToQuestionView(100, true);
                  this.toggleFocus(false);
                  this.onPrev();
                }}
              />
            ) : (
              <div />
            )}
            {isPrevious && this.canScrollNext() ? (
              <InlineButton
                text="Next"
                toRight
                onClick={() => {
                  this.toggleFocus(false);
                  this.onNext();
                }}
              />
            ) : (
              <div />
            )}
          </div>
        )}
        {mobile ? (
          <div style={{ height: setMobileHeight(), overflow: 'hidden' }}>
            <div className={`body-content ${final ? 'final' : ''}`} style={{ height: finalHeight }}>
              <QuestionScroller
                final={final}
                current={sessionStore.routes[SECTIONS.YOUR_BODY]}
                data={questions}
                onNext={this.onNext}
                onPrev={this.onPrev}
                disabled={mobile || initial || qestionSelectFocused || focus.some(el => el)}
                mobile={mobile}
                onClick={this.onClick}
                ref={el => (this.scroller = el)}
              />
            </div>
          </div>
        ) : (
          <div className={`body-content ${final ? 'final' : ''}`}>
            <QuestionScroller
              final={final}
              current={sessionStore.routes[SECTIONS.YOUR_BODY]}
              data={questions}
              onNext={this.onNext}
              onPrev={this.onPrev}
              disabled={mobile || initial || qestionSelectFocused || focus.some(el => el)}
              mobile={mobile}
              onClick={this.onClick}
              ref={el => (this.scroller = el)}
            />
          </div>
        )}
      </div>
    );
  }
}

export default BodySection;
