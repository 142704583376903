import React from 'react';
import { ReactComponent as Arrow } from '../../assets/icons/arrow.svg';

export default ({ onClick }) => {
  return (
    <div className="button-back" onClick={onClick} onKeyDown={onClick} role="button" tabIndex={0}>
      <Arrow />
    </div>
  );
};
