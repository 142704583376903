import { observable, action, computed } from 'mobx';
import cookie from 'cookie-parse';
import { STEPS, SECTIONS, PARAMS, VALUES, SHORT_VALUES, CONVERT_MATRIX } from './config';
import StorageService from '../../services/StorageService';
import CryptoService from '../../services/CryptoService';
import characterStore from '../characterStore';
import { getBrandsList, getBrand, bodyAnswers, favoriteBrands } from '../../services/http';
import userStore from '../userStore';

const defaultRoutes = {
    [SECTIONS.MAIN]: 0,
    [SECTIONS.YOUR_BODY]: 0,
    [SECTIONS.PERFECT_FIT]: 0,
    [SECTIONS.EVERYDAY]: 0,
};

const defaultBrands = {
    favorite: [],
    all: [],
    filtered: [],
};

const defaultParams = {
    [PARAMS.SCALE]: '',
    [PARAMS.TOPS]: '',
    [PARAMS.BOTTOMS]: '',
    [PARAMS.FAVORITE_BODY_PART]: '',
    [PARAMS.FRUSTRATING_FOR]: '',
    [PARAMS.AGE]: '',
    [PARAMS.SPEND_DAY]: '',
    [PARAMS.CAN_FIND]: [],
    [PARAMS.FEET]: '',
    [PARAMS.INCHES]: '',
    [PARAMS.LBS]: '',
    [PARAMS.SHOULDERS]: '',
    [PARAMS.BAND]: '',
    [PARAMS.CUP]: '',
    [PARAMS.CHEST]: '',
    [PARAMS.WAIST]: '',
    [PARAMS.LEGS]: '',
    [PARAMS.BODY_TYPE]: '',
    [PARAMS.CLOTHING_ONLINE]: '',
    [PARAMS.MAIN_SIZE]: '',
    [PARAMS.ALSO_BUY_SIZE]: '',
};

/* eslint-disable */
const deleteCookie = (name) => {
    document.cookie = name+'=' + ";expires=Thu, 01 Jan 1970 00:00:01 GMT ;revelle.westlinkclient.com ;path=/";
}

class SessionStore {
    @observable routes = defaultRoutes;

    @observable brands = defaultBrands;

    @observable toBottom = false;

    @observable params = defaultParams;

    constructor() {
        this.timer = null;
        const initialRoutes = StorageService.getItem('routes');
        const initialParams = StorageService.getItem('params');
        const initialBrands = StorageService.getItem('brands');
        if (initialBrands) {
            const decryptedBrands = CryptoService.decryptObj(initialBrands);
            if (decryptedBrands) {
                this.brands = decryptedBrands;
            }
        }
        (async () => {
            await userStore.registerUser();
            this.getBrands();
        })();
        if (initialRoutes && initialParams) {
            const decryptedRoutes = CryptoService.decryptObj(initialRoutes);
            const decryptedParams = CryptoService.decryptObj(initialParams);
            if (decryptedRoutes && decryptedParams) {
                this.routes = decryptedRoutes;
                this.params = decryptedParams;
                const shoulders = VALUES[this.params[PARAMS.SHOULDERS]];
                const cup = this.params[PARAMS.CUP];
                // const band = this.params[PARAMS.BAND];
                const chest = VALUES[this.params[PARAMS.CHEST]];
                const waist = VALUES[this.params[PARAMS.WAIST]];
                const legs = SHORT_VALUES[this.params[PARAMS.LEGS]];

                if (shoulders) characterStore.setShoulders(shoulders);
                if (cup) characterStore.setCupSizeChest(cup);
                if (waist) characterStore.setWaistSize(waist);
                // if (band) characterStore.setBandSizeChest(band);
                if (chest) characterStore.setChestSize(chest);
                // if (waist) characterStore.setHipsSize(waist);
                if (legs) characterStore.setLegs(legs);
            }
        }
    }

    cacheRouters = () => {
        StorageService.setItem('routes', CryptoService.encryptObj(this.routes));
    };

    cacheParams = () => {
        StorageService.setItem('params', CryptoService.encryptObj(this.params));
    };

    cacheBrands = () => {
        StorageService.setItem('brands', CryptoService.encryptObj(this.brands));
    };

    @action sendBodyAnswers = () => {
        const formatAnswer = (size) => {
            return CONVERT_MATRIX[size];
        };
        const shoulders = formatAnswer(this.params[PARAMS.SHOULDERS]);
        const chest = formatAnswer(this.params[PARAMS.CHEST]);
        const waist = formatAnswer(this.params[PARAMS.WAIST]);

        bodyAnswers(shoulders, chest, waist);
    };

    @action sendFavoriteBrands = () => {
        this.brands.favorite.forEach((brand) => {
            favoriteBrands({
                is_liked: true,
                brand: brand.id,
            });
        });
    };

    @action getBrands = async (word) => {
        const response = await getBrandsList(word);
        this.brands.all = response;
        // console.log('brands getted', response);
    };

    @action resetSession = () => {
        const cookiesFromWP = cookie.parse(document.cookie);
        const resetSession = cookiesFromWP["reset-questionare-session"];
        if(resetSession){
            this.routes = defaultRoutes;
            this.brands = defaultBrands;  
            this.toBottom = false;
            this.params = defaultParams;
            localStorage.clear();
            deleteCookie("reset-questionare-session");
        }
    }

    @action getFilteredBrands = (word, getAll) => {
        // console.log('word', word);
        clearTimeout(this.timer);
        this.timer = setTimeout(async () => {
            if (getAll) {
                this.brands.filtered = this.brands.all;
                console.log('this.brands.filtered', this.brands.filtered);
                return;
            }
            if (!word) {
                this.brands.filtered = [];
                return;
            }
            // console.log('before');
            if (!this.brands.all.length) {
                // console.log('inside if', this.brands.all.length);
                await this.getBrands(word);
            }
            // console.log('get filtered', this.brands.all, word);
            const regVar = new RegExp(word, 'gi');
            const filteredBrands = this.brands.all.filter((elem) => elem.title && elem.title.match(regVar));
            this.brands.filtered = filteredBrands;
        }, 50);
    };

    @action clearBrands = () => {
        this.brands.filtered = [];
    };

    @action brandToFavorites = async (id) => {
        const response = await getBrand(id);
        if (this.brands.favorite.find((brand) => brand.id === response.id)) {
            this.brands.favorite = this.brands.favorite.filter((brand) => brand.id !== response.id);
        } else {
            this.brands.favorite.push(response);
        }
        this.cacheBrands();
    };

    @action setFavoritesBrand = (brands) => {
        this.brands.favorite = brands;
        this.cacheBrands();
    };

    @action setQuestion = (num, section) => {
        this.toBottom = false;
        if (section in this.routes) {
            this.routes[section] = num;
            this.cacheRouters();
        }
    };

    @action decCurrentQuestion = (section) => {
        if (section in this.routes) {
            if (this.routes[section] !== 0) {
                this.toBottom = true;
                this.routes[section] -= 1;
                this.cacheRouters();
            }
        }
    };

    @action setParam = (key, value) => {
        // console.log('KEY', key, this.params);
        // if (key in this.params) {
            this.params[key] = value;
            this.cacheParams();
        // }
    };

    @action incCurrentStep = () => {
        this.routes[SECTIONS.MAIN] += 1;
        this.cacheRouters();
    };

    @action decCurrentStep = () => {
        if (this.routes[SECTIONS.MAIN] !== 0) {
            this.routes[SECTIONS.MAIN] -= 1;
            this.cacheRouters();
        }
    };

    @computed get currentPage() {
        if (this.routes[SECTIONS.MAIN] <= 1) return 1;
        if (this.routes[SECTIONS.MAIN] <= 2) return 2;
        if (this.routes[SECTIONS.MAIN] <= 3) return 3;
        if (this.routes[SECTIONS.MAIN] <= 4) return 4;
        return 5;
    }

    @computed get title() {
        return STEPS[this.routes[SECTIONS.MAIN]].title;
    }

    @action addItem = (item) => {
        const itemPos = this.params[PARAMS.CAN_FIND].indexOf(item);
        if (itemPos > -1) {
            this.params[PARAMS.CAN_FIND].splice(itemPos, 1);
        } else {
            this.params[PARAMS.CAN_FIND].push(item);
        }
        this.cacheParams();
    };
}

export default new SessionStore();
