import React, { useEffect } from 'react';
import Input from '../../atoms/Input';
import debounce from '../../../utils/debounce';
import { TIMEOUTS } from '../../../stores/sessionStore/config';
/* eslint-disable */
export default ({
  toNext = () => {},
  afterChangeTimeout = TIMEOUTS.YOUR_BODY,
  onChange = () => {},
  disabled = false,
  lines = [],
  onFocus = () => {}
}) => {
  useEffect(() => {
    const futerInput = Array.from(document.getElementsByTagName('input')).find(inp => inp.type === 'email');
    if (futerInput) {
      futerInput.onfocusin = () => {
        const formInputs = Array.from(document.getElementsByClassName('form-input'));
        // console.log(formInputs);
        if (formInputs) {
          formInputs.forEach(input => {
            // console.log('focus', input);
            input.readOnly = true;
          });
        }
      };
      futerInput.onblur = () => {
        const formInputs = Array.from(document.getElementsByClassName('form-input'));
        // console.log(formInputs);
        if (formInputs) {
          formInputs.forEach(input => {
            // console.log('blur', input);
            input.readOnly = false;
          });
        }
      };
    }
  }, []);
  const disableFuterInput = permission => {
    const futerInput = Array.from(document.getElementsByTagName('input')).find(inp => inp.type === 'email');
    if (futerInput) {
      if (permission) {
        futerInput.readOnly = false;
      } else {
        futerInput.readOnly = true;
      }
    }
  };

  const onNext = () => {
    debounce(() => {
      toNext();
    }, afterChangeTimeout)();
  };

  const onFocusHandler = () => {
    disableFuterInput();
    onFocus();
  };

  const onBlurHandler = () => {
    disableFuterInput(true);
  };

  const onChangeHandler = name => e => {
    const { value } = e.target;
    onChange(name, value);
    onNext();
  };

  return (
    <div className="question">
      <div className="question-cont">
        {lines.map(line => (
          <div className="question-line" key={line.id}>
            {line.values.map((item, index) => {
              const key = item + index;
              return (
                <React.Fragment key={key}>
                  <span className="back-text">{item.word}</span>
                  {item.input && (
                    <Input
                      defaultValue={item.value}
                      value={item.value}
                      placeholder={item.placeholder}
                      onChange={onChangeHandler(item.name)}
                      onlyNumber
                      disabled={disabled}
                      onFocus={onFocusHandler}
                      onBlur={onBlurHandler}
                      type="number"
                      id={item.inputId}
                      className="form-input"
                    />
                  )}
                </React.Fragment>
              );
            })}
          </div>
        ))}
      </div>
    </div>
  );
};
