import { FOCUS_RECT_CONFIG, SIZE_TYPES } from './config';

const defaultMatrix = {
  scaleX: 1,
  skewY: 0,
  skewX: 0,
  scaleY: 1,
  translateX: 0,
  translateY: 0
};

export const rects = {
  [FOCUS_RECT_CONFIG.CHEST]: {
    x: '245.710147572',
    y: '307.103968206',
    width: '134.620916693',
    height: '173.560851273'
  },
  [FOCUS_RECT_CONFIG.CHEST_SIZE]: {
    x: '233.8461799493',
    y: '303.018718943',
    width: '160.896049746',
    height: '83.5823635044'
  },
  [FOCUS_RECT_CONFIG.SHOULDERS]: {
    x: '153.39814758298917',
    y: '263.3172302246171',
    width: '315.5234222291',
    height: '137.9108997823'
  },
  [FOCUS_RECT_CONFIG.WAIST]: {
    x: '116.65207672118981',
    y: '422.81121826184403',
    width: '386.061637126',
    height: '192.474533783'
  },
  [FOCUS_RECT_CONFIG.LEGS]: {
    x: '195.64453125024937',
    y: '238.089885721',
    width: '243',
    height: '381.611358888'
  }
};

export const shoulders = {
  [SIZE_TYPES.SMALLEST]: {
    left: {
      scaleX: 0.7619,
      skewY: 0,
      skewX: 0,
      scaleY: 1,
      translateX: 74,
      translateY: 0
    },
    right: {
      scaleX: 0.7619,
      skewY: 0,
      skewX: 0,
      scaleY: 1,
      translateX: 76,
      translateY: 0
    }
  },
  [SIZE_TYPES.SMALL]: {
    left: {
      scaleX: 0.7619,
      skewY: 0,
      skewX: 0,
      scaleY: 1,
      translateX: 66,
      translateY: 0
    },
    right: {
      scaleX: 0.7619,
      skewY: 0,
      skewX: 0,
      scaleY: 1,
      translateX: 84,
      translateY: 0
    }
  },
  [SIZE_TYPES.MEDIUM]: {
    left: defaultMatrix,
    right: defaultMatrix
  },
  [SIZE_TYPES.BIG]: {
    left: {
      scaleX: 1.2,
      skewY: 0,
      skewX: 0,
      scaleY: 1,
      translateX: -50,
      translateY: 0
    },
    right: {
      scaleX: 1.2,
      skewY: 0,
      skewX: 0,
      scaleY: 1,
      translateX: -74,
      translateY: 0
    }
  },
  [SIZE_TYPES.BIGGEST]: {
    left: {
      scaleX: 1.3,
      skewY: 0,
      skewX: 0,
      scaleY: 1,
      translateX: -80,
      translateY: 0
    },
    right: {
      scaleX: 1.3,
      skewY: 0,
      skewX: 0,
      scaleY: 1,
      translateX: -105,
      translateY: 0
    }
  }
};

export const defaultChest = {
  left: defaultMatrix,
  right: defaultMatrix
};

export const defaultShoulders = {
  left: defaultMatrix,
  right: defaultMatrix
};

export const defaultWaist = {
  left: defaultMatrix,
  right: defaultMatrix
};

export const defaultCrotch = defaultMatrix;

export const defaultNavel = defaultMatrix;
