/* eslint-disable */
import React, { useState, useEffect } from 'react';
import { observer } from 'mobx-react';
import Input from '../../atoms/Input';
import ClothesCheckBox from '../../molecules/ClothesCheckBox';
import { TIMEOUTS } from '../../../stores/sessionStore/config';

export default observer(
  ({
    value,
    firstText,
    secondText,
    readyState,
    checks,
    onCheck = () => {},
    afterCheck = () => {},
    onFocus = () => {},
    didMount = () => {},
    didUpdate = () => {},
    timeout = TIMEOUTS.PERFECT_FIT,
    disabled = false,
    didUpdateParams
  }) => {
    const [focused, setSelectStatus] = useState(true);
    const [transform, setTransform] = useState(true);
    const [current, check] = useState(null);

    const onFocusHandler = () => {
      setSelectStatus(true);
      onFocus();
      setTimeout(() => setTransform(true), 100);
    };

    useEffect(didUpdate, didUpdateParams);

    useEffect(() => {
      didMount();
      if (readyState) {
        const number = checks.reduce((prev, elem, index) => {
          if (elem.value === readyState) {
            return index;
          }
          return prev;
        }, -1);
        check(number);
      }
    }, []);

    const onCheckHandler = number => () => {
      onCheck(checks[number].value);
      check(number);
      setTransform(false);
      setTimeout(() => setSelectStatus(false), 400);
      setTimeout(() => {
        afterCheck();
      }, timeout);
    };

    return (
      <div className="question">
        <div className="question-cont">
          <div className="question-line">
            <span className="back-text">{firstText}</span>
            <Input
              placeholder="select below"
              withoutChange
              defaultValue={value}
              onFocus={onFocusHandler}
              className="icons-input"
            />
            <span>{secondText}</span>
          </div>
          <div
            className="clothes-container"
            style={{
              display: focused && !disabled ? 'flex' : 'none',
              transform: transform && !disabled ? 'scaleX(1) scaleY(1)' : 'scaleX(0) scaleY(0)'
            }}
          >
            {checks.length === 4 ? (
              <>
                <div className="clothes-left">
                  {[0, 1].map(num => {
                    const card = checks[num];
                    const { text, icon } = card;
                    return (
                      <div className="clothes-card" key={num}>
                        <ClothesCheckBox icon={icon} checked={num === current} onClick={onCheckHandler(num)} />
                        <p className="clothes-text">{text}</p>
                      </div>
                    );
                  })}
                </div>
                <div className="clothes-right">
                  {[2, 3].map(num => {
                    const card = checks[num];
                    const { text, icon } = card;
                    return (
                      <div className="clothes-card" key={num}>
                        <ClothesCheckBox icon={icon} checked={num === current} onClick={onCheckHandler(num)} />
                        <p className="clothes-text">{text}</p>
                      </div>
                    );
                  })}
                </div>
              </>
            ) : (
              <div className="clothes-left">
                {[0, 1, 2].map(num => {
                  const card = checks[num];
                  const { text, icon } = card;
                  return (
                    <div className="clothes-card" key={num}>
                      <ClothesCheckBox icon={icon} checked={num === current} onClick={onCheckHandler(num)} />
                      <p className="clothes-text">{text}</p>
                    </div>
                  );
                })}
              </div>
            )}
          </div>
        </div>
      </div>
    );
  }
);
