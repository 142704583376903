import React from 'react';
import { ReactComponent as Cross } from '../../assets/icons/littleCross.svg';
import { ReactComponent as NoImage } from '../../assets/icons/no-image.svg';

export default ({ url, onClick }) => {
  const changedUrl = url ? url.replace('open', 'uc') : null;

  return (
    <div className="brands-card" style={{ backgroundImage: `url(${changedUrl})` }}>
      {changedUrl ? null : <NoImage className="no-image" />}
      <Cross role="button" onClick={onClick} tabIndex={0} onKeyDown={onClick} />
    </div>
  );
};
