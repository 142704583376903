import React from 'react';
import { observer } from 'mobx-react';
import NextButton from '../atoms/NextButton';
import sessionStore from '../../stores/sessionStore';

export default observer(() => {
  return (
    <div className="are-you-in-container">
      <h1 className="black-title">We want to help you find the right jeans for your body </h1>
      <h1 className="pink-title">- Are You In?</h1>
      <NextButton text="Yes, I'm In" onClick={sessionStore.incCurrentStep} />
    </div>
  );
});
