import { SORTED_SIZE_TYPES } from './config';
import { sizeFactors, DIRECTION_TYPES, factorsMerge } from '../../utils/sizeFactors';

const allSizeFactors = (() => {
  const arr = SORTED_SIZE_TYPES;
  // scaleX
  const maxScaleX = {
    left: 1.27725,
    right: 1.40663
  };
  const minScaleX = {
    left: 0.537921,
    right: 0.46399
  };
  const scaleX = sizeFactors(arr, maxScaleX, minScaleX, 'scaleX', DIRECTION_TYPES.LPRP_to_LPRP);

  // translateX
  const maxTranslateX = {
    left: -79.6467,
    right: -138.258
  };
  const minTranslateX = {
    left: 132.744,
    right: 182.249
  };
  const translateX = sizeFactors(arr, maxTranslateX, minTranslateX, 'translateX', DIRECTION_TYPES.LPRP_to_LMRM);
  const result = factorsMerge(scaleX, translateX);
  return result;
})();

export const hipsSizeFactors = allSizeFactors;
