import { SORTED_SIZE_TYPES } from './config';
import { sizeFactors, factorsMerge, DIRECTION_TYPES } from '../../utils/sizeFactors';

const allSizeFactors = (() => {
  const arr = SORTED_SIZE_TYPES;
  // translateX
  const maxTranslateX = {
    left: -5,
    right: 5
  };
  const minTranslateX = {
    left: 5,
    right: -5
  };
  const translateX = sizeFactors(arr, maxTranslateX, minTranslateX, 'translateX', DIRECTION_TYPES.LPRM_to_LMRP);
  // waistSkewY
  const maxWaistSkewY = {
    left: -0.12,
    right: 0.12
  };
  const minWaistSkewY = {
    left: 0.04,
    right: -0.04
  };
  const waistSkewY = sizeFactors(arr, maxWaistSkewY, minWaistSkewY, 'waistSkewY', DIRECTION_TYPES.LPRM_to_LMRP);
  // waistSkewX
  const maxWaistSkewX = {
    left: 0.12,
    right: -0.12
  };
  const minWaistSkewX = {
    left: -0.04,
    right: 0.04
  };
  const waistSkewX = sizeFactors(arr, maxWaistSkewX, minWaistSkewX, 'waistSkewX', DIRECTION_TYPES.LMRP_to_LPRM);
  // waistTranslateX
  const maxWaistTranslateX = {
    left: -56,
    right: 58
  };
  const minWaistTranslateX = {
    left: 20,
    right: -20
  };
  const waistTranslateX = sizeFactors(
    arr,
    maxWaistTranslateX,
    minWaistTranslateX,
    'waistTranslateX',
    DIRECTION_TYPES.LPRM_to_LMRP
  );
  // waistTranslateY
  const maxwaistTranslateY = {
    left: 34,
    right: -40
  };
  const minwaistTranslateY = {
    left: -9,
    right: 16
  };
  const waistTranslateY = sizeFactors(
    arr,
    maxwaistTranslateY,
    minwaistTranslateY,
    'waistTranslateY',
    DIRECTION_TYPES.LMRP_to_LPRM
  );
  const result = factorsMerge(translateX, waistSkewY, waistSkewX, waistTranslateX, waistTranslateY);

  return result;
})();

export const chestSizeFactors = allSizeFactors;
