import React, { Component } from 'react';
import { observer } from 'mobx-react';
import { ReactComponent as Search } from '../../assets/icons/search.svg';
import { ReactComponent as Cross } from '../../assets/icons/cross.svg';
import viewportStore from '../../stores/viewportStore';
import composedPathPolyfill from '../../utils/composedPathPolyfill';

@observer
class SearchInput extends Component {
  searchInput = null;

  componentDidMount() {
    document.addEventListener('click', this.onOutsideClick('searchInput'));
  }

  componentWillUnmount() {
    document.removeEventListener('click', this.onOutsideClick('searchInput'));
  }

  onOutsideClick = (elemId) => (e) => {
    const { onOutside = () => {} } = this.props;
    let path = null;
    if (e.composedPath) {
      path = Array.from(e.composedPath());
    } else {
      path = composedPathPolyfill(e);
    }
    if (!Array.from(path).find((el) => (el.id && el.id.match(elemId)) || el.className === 'search-list')) {
      onOutside();
    }
  };

  onBrandSelect = (id) => () => {
    const { onClick } = this.props;
    if (onClick) onClick(id);
  };

  onFocusHandler = () => {
    const { onFocus } = this.props;
    if (viewportStore.isMobile || viewportStore.isMobileL) {
      this.searchInput.scrollIntoView();
    }
    if (typeof onFocus === 'function') onFocus();
  };

  render() {
    const { placeholder, onBlur, brands, disabled, hidden, onChange, value, onClear } = this.props;

    return (
      <div
        id="searchInput"
        className={`search-input ${disabled ? 'disabled' : ''}`}
        ref={(el) => {
          this.searchInput = el;
        }}
      >
        <div className="search-input__container">
          <input
            type="text"
            value={value}
            placeholder={placeholder}
            onChange={onChange}
            onFocus={this.onFocusHandler}
            onBlur={onBlur}
            disabled={disabled}
          />
          <div
            role="button"
            onClick={onClear}
            mouseDown={onClear}
            onKeyDown={onClear}
            onTouch={onClear}
            tabIndex={0}
            className="search-input__button"
            id="clearBtn"
          >
            {!value.length ? <Search /> : <Cross />}
          </div>
        </div>
        {!hidden && !!brands && (
          <div className="search-list">
            {brands.map((brand) => (
              <div
                key={brand.id}
                className="search-item"
                role="button"
                tabIndex={0}
                onClick={this.onBrandSelect(brand.id)}
                onKeyDown={this.onBrandSelect(brand.id)}
              >
                {brand.title}
              </div>
            ))}
          </div>
        )}
      </div>
    );
  }
}

export default SearchInput;
