import React from 'react';
import { FOCUS_RECT_CONFIG } from '../../stores/characterStore/config';
import { rects } from '../../stores/characterStore/paths';
import SpringRect from '../molecules/SpringRect';

export default ({ currentFocus }) => {
  if (currentFocus === FOCUS_RECT_CONFIG.NONE) return null;
  const attrs = rects[currentFocus];
  return <SpringRect x={attrs.x} y={attrs.y} width={attrs.width} height={attrs.height} fill="#ffffff" />;
};
