import React from 'react';
import classNames from 'classnames';
import { ReactComponent as Arrow } from '../../assets/icons/arrow.svg';

export default ({ onClick, text, disabled }) => {
  const className = classNames({
    'next-button': !disabled,
    'next-button-disabled': disabled
  });
  return (
    <div
      className={className}
      onClick={disabled ? undefined : onClick}
      onKeyDown={disabled ? undefined : onClick}
      role="button"
      tabIndex={0}
    >
      <span className="next-button-text">
        {text} <Arrow />
      </span>
    </div>
  );
};
