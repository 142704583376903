import React, { Component } from 'react';
import { observer } from 'mobx-react';
import Question3 from './Question3';
import { ReactComponent as RuralIcon } from '../../../assets/icons/rural.svg';
import { ReactComponent as SuburbanIcon } from '../../../assets/icons/suburban.svg';
import { ReactComponent as UrbanIcon } from '../../../assets/icons/urban.svg';
import sessionStore from '../../../stores/sessionStore';
import QuestionInput from '../../templates/questionTemplates/QuestionInput';
import { SECTIONS, EVERYDAY_QUESTIONS, PARAMS } from '../../../stores/sessionStore/config';
import userStore from '../../../stores/userStore';
import QuestioIcons from '../../templates/questionTemplates/QuestioIcons';
import QuestionScroller from '../../organisms/Scroller';
import NextButton from '../../atoms/NextButton';
import { sendAnswer } from '../../../services/http';
import InlineButton from '../../atoms/InlineButton';
import viewportStore from '../../../stores/viewportStore';
import documentOffsetTop, { documentOffsetTopWithHeader } from '../../../utils/documentOffsetTop';

const checks = [
  {
    icon: <UrbanIcon />,
    text: 'Urban',
    value: 'urban'
  },
  {
    icon: <SuburbanIcon />,
    text: 'Suburban',
    value: 'suburban'
  },
  {
    icon: <RuralIcon />,
    text: 'Rural',
    value: 'rural'
  }
];
@observer
class EveryDaySection extends Component {
  constructor(props) {
    super(props);
    this.state = {
      initial: true,
      final: false,
      disabled: false,
      isPrevious: false,
      finalHeight: ''
    };
  }

  componentDidMount() {
    const currentQuestion = sessionStore.routes[SECTIONS.EVERYDAY];
    setTimeout(() => {
      this.setState({ initial: false });
    }, 100);
    // console.log(currentQuestion, 'didmount');
    if (currentQuestion === 2) {
      this.toggleDisabled(true);
    }

    if (currentQuestion === 3) {
      this.setState({ final: true });
    } else if (this.canScrollNext(currentQuestion)) {
      this.setState({ isPrevious: true });
    }
    document.addEventListener('keydown', this.onEnterHandler);
  }

  componentDidUpdate() {
    const { disabled, final, finalHeight } = this.state;
    const currentRoute = sessionStore.routes[SECTIONS.EVERYDAY];
    const mobile = viewportStore.isMobile || viewportStore.isMobileL;

    // console.log(currentRoute, 'didupdate');
    if (currentRoute === 1 && !this.canScrollNext() && !disabled) {
      this.toggleDisabled(true);
    }
    if (currentRoute === 2 && !disabled) {
      this.toggleDisabled(true);
    }
    if (currentRoute === 0 && disabled) {
      this.toggleDisabled(false);
    }
    if (final && !finalHeight) {
      const title = document.getElementsByClassName('title-cont')[0];
      // console.log(title);
      if (mobile) {
        window.scrollTo({ top: documentOffsetTop(title), behavior: 'smooth' });
      } else {
        window.scrollTo({ top: documentOffsetTopWithHeader(title), behavior: 'smooth' });
      }

      setTimeout(() => {
        this.setFinalContentHeight();
      }, 100);
    }
  }

  componentWillUnmount() {
    document.removeEventListener('keydown', this.onEnterHandler);
  }

  onEnterHandler = event => {
    const { final } = this.state;
    const currentRoute = sessionStore.routes[SECTIONS.EVERYDAY];
    if (event.key === 'Enter' && !final && this.canScrollNext()) {
      sessionStore.setQuestion(currentRoute + 1, SECTIONS.EVERYDAY);
    }
  };

  setFinalContentHeight = () => {
    const finalContent = document.getElementsByClassName('scroller-content')[0];
    this.setState({ finalHeight: finalContent.getBoundingClientRect().height });
  };

  canScrollNext = () => {
    const currentRoute = sessionStore.routes[SECTIONS.EVERYDAY];
    let permission = false;
    if (!EVERYDAY_QUESTIONS[currentRoute]) return permission;
    permission = Object.values(EVERYDAY_QUESTIONS[currentRoute]).every(key => {
      return sessionStore.params[key];
    });
    return permission;
  };

  onClick = num => {
    sessionStore.setQuestion(num, SECTIONS.EVERYDAY);
  };

  onNext = () => {
    const currentQuestion = sessionStore.routes[SECTIONS.EVERYDAY];
    const { final } = this.state;
    this.setState({ isPrevious: false });
    if (this.canScrollNext() && !final && currentQuestion < 2) {
      sessionStore.setQuestion(currentQuestion + 1, SECTIONS.EVERYDAY);
    }
    if (this.canScrollNext(currentQuestion)) {
      this.setState({ isPrevious: true });
    } else {
      this.setState({ isPrevious: false });
    }
  };

  onFin = () => {
    // console.log('FIN');
    this.setState({ final: true });
    sessionStore.setQuestion(sessionStore.routes[SECTIONS.EVERYDAY] + 1, SECTIONS.EVERYDAY);
    sendAnswer('In a given week you can find me', sessionStore.params[PARAMS.CAN_FIND].join());
    this.toggleDisabled(false);
    window.scrollTo({ top: 0 });
  };

  onPrev = () => {
    const { final } = this.state;
    if (sessionStore.routes[SECTIONS.EVERYDAY] > 0 && !final) {
      this.setState({ isPrevious: true });
      sessionStore.setQuestion(sessionStore.routes[SECTIONS.EVERYDAY] - 1, SECTIONS.EVERYDAY);
    }
  };

  onEnd = () => {
    document.cookie = `intake_form_id=${userStore.userData.token};path=/`;
    document.location.href = `${document.location.origin}/register`;
  };

  toggleDisabled = disabled => {
    this.setState({ disabled: !!disabled });
  };

  scrollToQuestionView = () => {
    const mobile = viewportStore.isMobile || viewportStore.isMobileL;
    const buutonGroup = document.getElementsByClassName('button-group')[0];
    if (mobile && buutonGroup) {
      window.scrollTo({ top: buutonGroup.offsetTop, behavior: 'smooth' });
    }
  };

  onFocusIcons = () => {
    const { final } = this.state;
    const mobile = viewportStore.isMobile || viewportStore.isMobileL;
    if (mobile && final) {
      setTimeout(this.setFinalContentHeight, 500);
    }
  };

  render() {
    const { final, disabled, initial, isPrevious, finalHeight } = this.state;
    const { routes, params } = sessionStore;
    const age = params[PARAMS.AGE];
    const spendDay = params[PARAMS.SPEND_DAY];

    const mobile = viewportStore.isMobile || viewportStore.isMobileL;

    const questions = [
      <QuestionInput
        disabled={final ? false : routes[SECTIONS.EVERYDAY] > 0}
        afterChangeTimeout={1500}
        toNext={() => {
          if (mobile) {
            if (!isPrevious) {
              this.onNext();
            }
          } else {
            this.onNext();
          }
        }}
        onChange={(name, text) => {
          sessionStore.setParam(PARAMS.AGE, text);
          sendAnswer('your age', text);
        }}
        onFocus={this.onFocusHandler}
        lines={[
          {
            values: [
              { word: 'I am ', value: age, placeholder: 'your age', name: PARAMS.FEET, input: true },
              { word: '  years old.', input: false }
            ],
            id: 1
          }
        ]}
      />,
      <QuestioIcons
        value={spendDay}
        onFocus={() => {}}
        disabled={final ? false : routes[SECTIONS.EVERYDAY] > 1}
        firstText="I typically spend my days in "
        secondText=" areas."
        readyState={spendDay}
        didUpdate={() => {
          if (mobile && routes[SECTIONS.EVERYDAY]) {
            this.toggleDisabled(true);
          }
        }}
        didUpdateParams={[routes[SECTIONS.EVERYDAY]]}
        checks={checks}
        onCheck={text => {
          sessionStore.setParam(PARAMS.SPEND_DAY, text);
          sendAnswer('I typically spend my days in ', text);
          if (mobile) {
            if (isPrevious) {
              this.toggleDisabled(true);
            } else {
              this.toggleDisabled(false);
            }
          } else {
            this.toggleDisabled(false);
          }
        }}
        afterCheck={() => {
          if (mobile && final) {
            this.setFinalContentHeight();
          }
          if (routes[SECTIONS.EVERYDAY] === 1 /* && !mobile */) {
            if (mobile) {
              if (!isPrevious) {
                this.onNext();
              }
            } else {
              this.onNext();
            }
          }
        }}
      />,
      <Question3
        final={final}
        onFocus={this.onFocusHandler}
        onNext={() => {
          this.setState({ final: true });
          sessionStore.setQuestion(sessionStore.routes[SECTIONS.EVERYDAY] + 1, SECTIONS.EVERYDAY);
          sendAnswer('In a given week you can find me', sessionStore.params[PARAMS.CAN_FIND].join());
          this.toggleDisabled(false);
          window.scrollTo({ top: 0 });
        }}
        value={sessionStore.params[PARAMS.CAN_FIND].map(el => el.toLowerCase()).join()}
      />,
      final ? (
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          <NextButton text="That's my Life" onClick={this.onEnd} />
        </div>
      ) : null
    ];
    // console.log(sessionStore.routes[SECTIONS.EVERYDAY], final);
    const setMobileHeight = () => {
      const focused = disabled;
      if (final) {
        return 'auto';
      }
      if (sessionStore.routes[SECTIONS.EVERYDAY] === 2) {
        return '520px';
      }

      if (focused) {
        return '300px';
      }
      return '130px';
    };

    return (
      <div className="everyday-section">
        {mobile && !final && (
          <div className="button-group">
            {sessionStore.routes[SECTIONS.EVERYDAY] !== 0 ? (
              <InlineButton text="Previous" onClick={this.onPrev} />
            ) : (
              <div />
            )}

            {sessionStore.routes[SECTIONS.EVERYDAY] !== 2 && isPrevious ? (
              <InlineButton
                text="Next"
                toRight
                onClick={sessionStore.routes[SECTIONS.EVERYDAY] === 2 ? this.onFin : this.onNext}
              />
            ) : (
              <div />
            )}
          </div>
        )}
        {mobile ? (
          <div style={{ height: setMobileHeight(), overflow: 'hidden' }}>
            <div className={`body-content ${final ? 'final' : ''}`} style={{ height: finalHeight }}>
              <QuestionScroller
                disabled={mobile || initial || disabled || sessionStore.routes[SECTIONS.EVERYDAY] === 2}
                final={final}
                current={sessionStore.routes[SECTIONS.EVERYDAY]}
                data={questions}
                onNext={this.onNext}
                onPrev={this.onPrev}
                onClick={this.onClick}
                mobile={mobile}
                withoutClick
                firstCup={10}
              />
            </div>
          </div>
        ) : (
          <div className={`body-content ${final ? 'final' : ''}`}>
            <QuestionScroller
              disabled={mobile || initial || disabled || sessionStore.routes[SECTIONS.EVERYDAY] === 2}
              final={final}
              current={sessionStore.routes[SECTIONS.EVERYDAY]}
              data={questions}
              onNext={this.onNext}
              onPrev={this.onPrev}
              onClick={this.onClick}
              mobile={mobile}
              withoutClick
              firstCup={10}
            />
          </div>
        )}
      </div>
    );
  }
}

export default EveryDaySection;
