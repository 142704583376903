import React from 'react';
import { observer } from 'mobx-react';
import TransformSpringPath from '../molecules/TransformSpringPath';
import characterStore from '../../stores/characterStore';

export default observer(() => {
  const { left, right } = characterStore.chest;
  return (
    <>
      <TransformSpringPath
        scaleX={left.scaleX}
        skewY={left.skewY}
        skewX={left.skewX}
        scaleY={left.scaleY}
        translateX={left.translateX}
        translateY={left.translateY}
        className="prefix__cls-2"
        d="M257.723,305.957a16.545,16.545,0,0,1,.43,13.565,33.827,33.827,0,0,1-1.58,3.1,23.163,23.163,0,0,0-1.325,3.152,28.117,28.117,0,0,0-1.354,6.694,33.087,33.087,0,0,0,1.736,13.464,27.991,27.991,0,0,0,7.223,11.346,23.573,23.573,0,0,0,11.9,5.945,22.625,22.625,0,0,0,18.923-4.41,33.553,33.553,0,0,0,4.869-4.568,48.679,48.679,0,0,0,7.288-11.5l.192.058a30.355,30.355,0,0,1-2.351,6.594,32.5,32.5,0,0,1-3.7,6.015,23.653,23.653,0,0,1-18.419,9.7,29.865,29.865,0,0,1-7.063-.539,24.862,24.862,0,0,1-12.447-6.443,28.923,28.923,0,0,1-7.332-11.893A33.913,33.913,0,0,1,253.2,332.4a28.744,28.744,0,0,1,1.517-6.817,23.807,23.807,0,0,1,1.418-3.2,32.628,32.628,0,0,0,1.62-3.037,16.232,16.232,0,0,0-.2-13.3Z"
      />
      <TransformSpringPath
        scaleX={right.scaleX}
        skewY={right.skewY}
        skewX={right.skewX}
        scaleY={right.scaleY}
        translateX={right.translateX}
        translateY={right.translateY}
        className="prefix__cls-2"
        d="M369.744,305.515a16.232,16.232,0,0,0-.2,13.3,32.392,32.392,0,0,0,1.62,3.037,23.758,23.758,0,0,1,1.417,3.2,28.676,28.676,0,0,1,1.517,6.816,33.9,33.9,0,0,1-1.515,13.84,28.905,28.905,0,0,1-7.332,11.892A24.868,24.868,0,0,1,352.8,364.04a29.856,29.856,0,0,1-7.062.538,23.656,23.656,0,0,1-18.42-9.7,32.491,32.491,0,0,1-3.7-6.015,30.313,30.313,0,0,1-2.351-6.593l.191-.059a48.714,48.714,0,0,0,7.289,11.5,33.553,33.553,0,0,0,4.869,4.568,22.621,22.621,0,0,0,18.923,4.41,23.573,23.573,0,0,0,11.9-5.945,27.978,27.978,0,0,0,7.222-11.346,33.07,33.07,0,0,0,1.736-13.464,28.116,28.116,0,0,0-1.353-6.694,23.344,23.344,0,0,0-1.325-3.152,33.827,33.827,0,0,1-1.58-3.1,16.545,16.545,0,0,1,.429-13.565Z"
      />
    </>
  );
});
