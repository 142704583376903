import React, { useState, useEffect } from 'react';
import { observer } from 'mobx-react';
import SearchInput from '../atoms/SearchInput';
import NextButton from '../atoms/NextButton';
import sessionStore from '../../stores/sessionStore';
import BransCard from '../atoms/BransCard';

export default observer(() => {
  const { brands, clearBrands, brandToFavorites, getFilteredBrands } = sessionStore;
  const disabled = brands.favorite.length >= 6;
  const [hidden, toggle] = useState(false);
  // const [focused, toggleFocus] = useState(false);

  const [value, setValue] = useState('');

  useEffect(() => {
    if (value && !brands.all.length) {
      getFilteredBrands(value);
    }
  }, []);
  const onOutsideHandler = () => {
    toggle(true);
  };

  const onChangeHandler = (event) => {
    const { value: val } = event.target;
    setValue(val);
    getFilteredBrands(val);
    toggle(false);
  };
  const onClick = (id) => {
    brandToFavorites(id);
    setValue('');
    clearBrands();
  };
  const onDel = (id) => () => onClick(id);

  const onFocusHandler = () => {
    toggle(false);
    // toggleFocus(true); //
    getFilteredBrands(undefined, true);
  };

  const onClear = () => {
    if (!value.length) {
      console.log('CLICK ON SEARCH');
      onFocusHandler();
    } else {
      setValue('');
      clearBrands();
    }
  };

  /* eslint-disable react/no-array-index-key */
  const createBrandsView = (array, num) =>
    array
      .reverse()
      .reduce((prev, current, index) => {
        if (!(index % num)) {
          prev.push([]);
          prev[prev.length - 1].push(current);
        } else {
          prev[prev.length - 1].push(current);
        }
        return prev;
      }, [])
      .map((row, index) => (
        <div className="row" key={index}>
          {row.map((el) => {
            return <BransCard key={el.id} onClick={onDel(el.id)} url={el.image} />;
          })}
        </div>
      ));

  // const outSideClick = (event) => {
  //   if (event.target.id === 'clearBtn' && !value.length) return;
  //   if (!focused) {
  //     toggle(true);
  //   }
  // };
  console.log('!hidden && !!brands ', !hidden && !!brands);
  console.log('!hidden', !hidden);
  console.log('!!brands ', !!brands);

  return (
    <div
      className="favorit-brands"
      // onClick={outSideClick}
      // onKeyDown={outSideClick}
      role="button"
      style={{ outline: 'none' }}
      tabIndex={0}
    >
      <SearchInput
        onClear={onClear}
        placeholder="Enter your favorite brands here"
        onChange={onChangeHandler}
        timeout={200}
        brands={brands.filtered}
        onClick={onClick}
        disabled={disabled}
        hidden={hidden}
        value={value}
        onOutside={onOutsideHandler}
        onFocus={onFocusHandler}
      />
      <h4 className="mini-title">Your Brands</h4>
      {brands.favorite.length ? (
        <div className="brands-container">{createBrandsView(brands.favorite, 3)}</div>
      ) : (
        <p className="grey-text-mini">Please select at least 3 brands.</p>
      )}

      {brands.favorite.length >= 3 && (
        <NextButton
          text="Love these"
          onClick={() => {
            sessionStore.incCurrentStep();
            sessionStore.sendFavoriteBrands();
          }}
        />
      )}
    </div>
  );
});
