import React from 'react';
import { observer } from 'mobx-react';
import TransformSpringPath from '../molecules/TransformSpringPath';
import characterStore from '../../stores/characterStore';

export default observer(() => {
  const { scaleX, skewY, skewX, scaleY, translateX, translateY } = characterStore.navel;
  return (
    <TransformSpringPath
      scaleX={scaleX}
      skewY={skewY}
      skewX={skewX}
      scaleY={scaleY}
      translateX={translateX}
      translateY={translateY}
      className="prefix__cls-2"
      d="M314.841,433.4a4.975,4.975,0,0,0,1.539,3.365,3.176,3.176,0,0,0,1.645.56,14.668,14.668,0,0,0,2.033,0,4.3,4.3,0,0,1-2.02.776,3.14,3.14,0,0,1-2.269-.545,3.27,3.27,0,0,1-1.184-1.994A3.61,3.61,0,0,1,314.841,433.4Z"
    />
  );
});
